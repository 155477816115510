export const NUM_FRACTION_DIGITS = 4;

export interface UpdateFromObjectOpts {
  undoable?: boolean;
  updateRedux?: boolean;
  doInvalidate?: boolean;
  forceInit?: boolean;
  applyVersionFixes?: boolean;
  applyVersionFixesData?: Record<string, any>;
  refreshActiveSelection?: boolean;
  checkForDurationUpdate?: boolean;
  replayPosterOnUpdateDone?: boolean;
  checkForSlideshowIntroAnimationPadding?: boolean;
  onItemAddFail?: () => void;
}
