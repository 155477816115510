import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {TemplatesFiltersData} from '@Libraries/editor-templates-library';

interface State {
  filters: TemplatesFiltersData;
}

const initialState: State = {
  filters: {},
};

export const templatesPopupSlice = createSlice({
  name: 'templatesPopup',
  initialState,
  reducers: {
    setFiltersData: (state, action: PayloadAction<TemplatesFiltersData>) => {
      state.filters = action.payload;
    },
  },
});

export const {setFiltersData} = templatesPopupSlice.actions;
export const templatesPopupReducer = templatesPopupSlice.reducer;
