import {createAsyncThunk} from '@reduxjs/toolkit';
import type {StateProps} from '@Components/base-grid/base-grid-reducer';
import {deleteGridItems} from '@Components/base-grid/base-grid-reducer';
import {
  deleteEmailSignupFormLoading,
  duplicateEmailSignupFormLoading,
  findGridItem,
  SIGNUP_GRID_ID,
  updateEmailSignupFormLoadingNotifications,
} from '@Libraries/email-signup-form-library';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {AjaxSuccessResponse} from '@Utils/ajax.util';
import {decrementSignupFormCount} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';

interface EmailSignupFormParams {
  idEmailSignupForm: string;
}

interface EmailSignupFormSaveNotificationsParams {
  idEmailSignupForm: string;
  areNotificationsEnabled: boolean;
}

export const deleteEmailSignupForm = createAsyncThunk('deleteEmailSignupForm', async (data: EmailSignupFormParams, {getState, dispatch, rejectWithValue}) => {
  const {grids} = getState() as {grids: StateProps};

  const grid = grids.gridsHashmap[SIGNUP_GRID_ID];
  showLoading(deleteEmailSignupFormLoading);

  try {
    const response = await window.PMW.writeLocal('formmaker/deleteEmailSignupForm', {
      idEmailSignupForm: data.idEmailSignupForm,
    });
    const gridItem = findGridItem(grid, data.idEmailSignupForm);

    if (gridItem) {
      dispatch(
        deleteGridItems({
          gridId: SIGNUP_GRID_ID,
          gridSelectionGroupId: SIGNUP_GRID_ID,
          itemIdsToDelete: [data.idEmailSignupForm],
        })
      );

      dispatch(decrementSignupFormCount());
    }

    hideLoading(deleteEmailSignupFormLoading);
    return response;
  } catch (err) {
    rejectWithValue(err);
    hideLoading(deleteEmailSignupFormLoading);
  }
});

export const duplicateEmailSignupForm = createAsyncThunk('duplicateEmailSignupForm', async (data: EmailSignupFormParams, thunkAPI) => {
  showLoading(duplicateEmailSignupFormLoading, {text: window.i18next.t('pmwjs_signup_form_duplicate')});
  const response = await window.PMW.writeLocal('formmaker/duplicateEmailSignupForm', {
    idEmailSignupForm: data.idEmailSignupForm,
  });
  hideLoading(duplicateEmailSignupFormLoading);
  return response;
});

export const updateEmailSignupFormNotificationsPreference = createAsyncThunk(
  'updateEmailSignupFormNotificationsPreference',
  async (data: EmailSignupFormSaveNotificationsParams, thunkAPI) => {
    showLoading(updateEmailSignupFormLoadingNotifications, {text: window.i18next.t('pmwjs_updating')});
    const response = (await window.PMW.writeLocal(`formmaker/saveNotificationsPreference/${data.idEmailSignupForm}`, {
      areNotificationsEnabled: data.areNotificationsEnabled,
    })) as AjaxSuccessResponse;
    hideLoading(updateEmailSignupFormLoadingNotifications);
    return response;
  }
);
