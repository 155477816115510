import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {EventsHomeReduxState, UserEventsScheduleVO} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {EventsHomeEditingStep, EventsHomePageLayout} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {LoadingStates} from '@Utils/loading.util';
import {PMW_COLORS_PRIMARY} from '@Utils/color.util';
import type {SocialMediaProfile, SocialMediaProfileLinksMap} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {mystuffEventsHomeExtraReducers} from '@Components/mystuff-events-home/mystuff-events-home-extra-reducers';
import {initSocialMediaProfileLinks} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {DEFAULT_EVENTS_STYLE_FONT_FAMILY} from "@Libraries/events.library";

const eventsHomeSliceInitialState: EventsHomeReduxState = {
  isEditing: false,
  wizardCurrentStep: EventsHomeEditingStep.PAGE,
  loadingState: LoadingStates.NOT_LOADED,
  eventsHomeHashedId: '',
  isFormInErrorState: false,
  isEmptyState: false,
  pageDetailsFormData: {
    title: 'Upcoming Events',
    description: '',
    color: PMW_COLORS_PRIMARY.PRIMARY_100,
    fontFamily: DEFAULT_EVENTS_STYLE_FONT_FAMILY,
    imageURL: undefined,
    coverPhotoURL: undefined,
    doShowPastEvents: true,
    doRemoveWatermark: false,
    eventsHomeLayoutPreference: EventsHomePageLayout.LIST,
  },
  contactDetailsFormData: {
    email: '',
    phoneNumber: '',
    socialMediaProfilesInput: initSocialMediaProfileLinks(),
  },
  recentEmailsUsed: [],
  recentPhoneNumbersUsed: [],
  savedEventsScheduleVO: undefined
};
const mystuffEventsHomeSlice = createSlice({
  name: 'myStuffEventsHomeSlice',
  initialState: eventsHomeSliceInitialState,
  reducers: {
    setEventsHomePageTitle: (state, action: PayloadAction<string>): void => {
      state.pageDetailsFormData.title = action.payload;
    },

    setEventsHomePageDescription: (state, action: PayloadAction<string>): void => {
      state.pageDetailsFormData.description = action.payload;
    },

    setCurrentStep: (state, action: PayloadAction<number>): void => {
      state.wizardCurrentStep = action.payload;
    },

    setEventsHomePageColor: (state, action: PayloadAction<string>): void => {
      state.pageDetailsFormData.color = action.payload;
    },

    setHomePageHeadingFontFamily: (state, action: PayloadAction<string>): void => {
      state.pageDetailsFormData.fontFamily = action.payload;
    },

    setEventsHomePageImage: (state, action: PayloadAction<string>): void => {
      state.pageDetailsFormData.imageURL = action.payload;
    },

    setEventsHomePageCoverPhoto: (state, action: PayloadAction<string | undefined>): void => {
      state.pageDetailsFormData.coverPhotoURL = action.payload;
    },

    setEventsHomeShowPastEvents: (state, action: PayloadAction<boolean>): void => {
      state.pageDetailsFormData.doShowPastEvents = action.payload;
    },

    setEventsHomeRemoveWatermark: (state, action: PayloadAction<boolean>): void => {
      state.pageDetailsFormData.doRemoveWatermark = action.payload;
    },

    setEventsHomeEmail: (state, action: PayloadAction<string>): void => {
      state.contactDetailsFormData.email = action.payload;
    },

    setEventsHomePhoneNumber: (state, action: PayloadAction<string>): void => {
      state.contactDetailsFormData.phoneNumber = action.payload;
    },

    setEventsHomeSocialProfileLink: (state, action: PayloadAction<{profile: SocialMediaProfile; url: string}>): void => {
      state.contactDetailsFormData.socialMediaProfilesInput = {...state.contactDetailsFormData.socialMediaProfilesInput, [action.payload.profile]: action.payload.url};
    },

    setEventsHomeSocialProfileLinks: (state, action: PayloadAction<SocialMediaProfileLinksMap>): void => {
      state.contactDetailsFormData.socialMediaProfilesInput = action.payload;
    },

    setIsEventsHomeFormErrorState: (state, action: PayloadAction<boolean>): void => {
      state.isFormInErrorState = action.payload;
    },

    setSavedEventsScheduleVO: (state, action: PayloadAction<UserEventsScheduleVO>): void => {
      state.savedEventsScheduleVO = action.payload;
    },

    resetEditedEventsScheduleStateToOriginal: (state): void  => {
      if (!state.savedEventsScheduleVO) {
        return;
      }
      mapUserEventsScheduleVOToFormState(state, state.savedEventsScheduleVO);
    }
  },
  extraReducers: mystuffEventsHomeExtraReducers,
});

const resetStateToDefault = (state: EventsHomeReduxState): void => {
  state.wizardCurrentStep = EventsHomeEditingStep.PAGE;
  state.loadingState = LoadingStates.NOT_LOADED;
};

export const mapUserEventsScheduleVOToFormState = (state: EventsHomeReduxState, eventsSchedule: UserEventsScheduleVO): void => {
  state.pageDetailsFormData = {
    title: eventsSchedule.title,
    description: eventsSchedule.description ?? '',
    doShowPastEvents: eventsSchedule.doShowPastEvents ?? true,
    doRemoveWatermark: eventsSchedule.doRemoveWatermark ?? false,
    imageURL: eventsSchedule.profilePictureURL,
    coverPhotoURL: eventsSchedule.coverPhotoURL,
    fontFamily: eventsSchedule.fontFamily ?? DEFAULT_EVENTS_STYLE_FONT_FAMILY,
    color: eventsSchedule.primaryColor ?? PMW_COLORS_PRIMARY.PRIMARY_100,
    eventsHomeLayoutPreference: eventsSchedule.viewType ? (eventsSchedule.viewType as EventsHomePageLayout) : EventsHomePageLayout.LIST,
  };

  state.contactDetailsFormData = {
    email: eventsSchedule.email,
    phoneNumber: eventsSchedule.phoneNumber ?? '',
    socialMediaProfilesInput: {...initSocialMediaProfileLinks(), ...(eventsSchedule.socialProfileLinks ?? {})},
  };
}

export const {
  setEventsHomePageTitle,
  setEventsHomePageDescription,
  setCurrentStep,
  setEventsHomePageImage,
  setEventsHomePageColor,
  setHomePageHeadingFontFamily,
  setEventsHomeShowPastEvents,
  setEventsHomeRemoveWatermark,
  setEventsHomeEmail,
  setEventsHomeSocialProfileLink,
  setEventsHomePhoneNumber,
  setEventsHomePageCoverPhoto,
  setIsEventsHomeFormErrorState,
  setEventsHomeSocialProfileLinks,
  setSavedEventsScheduleVO,
  resetEditedEventsScheduleStateToOriginal
} = mystuffEventsHomeSlice.actions;
export const myStuffEventsHomeReducer = mystuffEventsHomeSlice.reducer;
