import type {ReactElement} from 'react';
import React from 'react';
import {POPUP_TOGGLE_ITEM_CLASS} from '@Components/popup-menu/popup-menu';
import {Text, TextSize} from '@Components/text';
import {ToggleSwitch, ToggleSwitchV2Size} from '@Components/toggle-switch-v2';
import styles from './popup-menu-toggle-item.module.scss';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';

interface PopupMenuToggleItemProps {
  text: string;
  isChecked: boolean;
  onChange: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasTopSeparator?: boolean;
  isListItem?: boolean;
  hasPremiumUpselling?: boolean;
  leftIcon?: string;
  className?: string;
}

export function PopupMenuToggleItem({
  text,
  isChecked,
  onChange,
  isDisabled = false,
  isLoading = false,
  hasTopSeparator = true,
  isListItem = true,
  hasPremiumUpselling = false,
  leftIcon = '',
  className = '',
}: PopupMenuToggleItemProps): ReactElement {
  const getTopSeparator = (): ReactElement | null => {
    if (!hasTopSeparator) {
      return null;
    }

    return <div className="border-s-standard radius-8 spacing-m-r-2 spacing-m-l-2 _no-border-bottom _no-border-right _no-border-left" />;
  };

  const getInnerContent = (): ReactElement => {
    return (
      <div className="flex-v-row _full-width">
        {getTopSeparator()}
        <div className={`flex-row-align-center flex-content-between ${POPUP_TOGGLE_ITEM_CLASS} ${className} popup-toggle-menu-item ${styles.toggleItemMargin} spacing-p-l-3 spacing-p-r-2 spacing-p-t-2 spacing-p-b-2 ${styles.itemContainer}`}>
          <div className="flex-row-align-center _overflow-hidden _full-width">
            {leftIcon ? <Icon icon={leftIcon} size={IconSize.SIZE_ICON_16} className="spacing-m-r-2 content-body" /> : null}
            <Text className="_text-nowrap -truncated-text" val={text} size={TextSize.XSMALL} />
            {hasPremiumUpselling ? <Icon icon="icon-crown" size={IconSize.SIZE_ICON_16} className={`spacing-m-l-2 ${styles.premiumIcon}`} /> : null}
          </div>
          <ToggleSwitch
            className={styles.popupToggleSwitch}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isChecked={isChecked}
            onChange={onChange}
            size={ToggleSwitchV2Size.SMALL}
            iconClassName={hasPremiumUpselling ? styles.premiumIcon : ''}
          />
        </div>
      </div>
    );
  };

  return isListItem ? <li className="flexbox _unpadded _unmargin _full-width">{getInnerContent()}</li> : getInnerContent();
}
