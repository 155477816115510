import type {RefObject} from 'react';
import {useEffect, useRef} from 'react';

export const useIsMounted = (): RefObject<boolean> => {
  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;

    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return isComponentMounted;
};
