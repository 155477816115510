import {darkenColor, generateColorPaletteForWeight} from '@Utils/color.util';
import type {EventStyleColors} from '@Components/event-wizard/event-style/event-style.types';

export const getNewStyleColors = (primaryColor: string): EventStyleColors => {
  const getLightestShadeNonWhite = (colors: Array<string>): string => {
    for (let i = colors.length - 1; i >= 0; i--) {
      if (colors[i].toLowerCase() !== '#ffffff') {
        return colors[i];
      }
    }
    return '';
  };

  const lighterShadeWeight = 30;
  const lightShadeWeight = 35;
  const darkShadeWeight = 25;
  const darkerShadeWeight = 30;

  const backgroundLighterArr = generateColorPaletteForWeight(primaryColor, lighterShadeWeight);
  const backgroundLighter = getLightestShadeNonWhite(backgroundLighterArr);

  const backgroundLightArr = generateColorPaletteForWeight(primaryColor, lightShadeWeight);
  const backgroundLight = getLightestShadeNonWhite(backgroundLightArr);

  const darkTextColor = darkenColor(primaryColor, darkShadeWeight);
  const darkerTextColor = darkenColor(primaryColor, darkerShadeWeight);

  return {
    primary: primaryColor,
    lighter: backgroundLighter,
    light: backgroundLight,
    dark: darkTextColor,
    darker: darkerTextColor,
  };
};
