import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import {rgbaToHexString} from '@Utils/color.util';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {BOLD_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import type {TabsItem} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';

/**
 * factor to convert lineHeight value for fabric
 * @type {number}
 */
const lineHeightDfrncFactor = 113;

/**
 * Backwards Compatibility Note: Flash and Fabric have different
 * definitions of lineHeight. Flash uses the lineHeight value
 * as number of pixels, whereas Fabric uses the value as a
 * multiplier for the current font size. Since current posters
 * will have their values saved from Flash, we'll need to check
 * for the builder version of this poster and use that
 * definition of lineHeight accordingly.
 *
 * UPDATE: In recent posters, Flash's lineHeight has been
 * a percentage value, without the % sign. e.g. 1.3 has been
 * stored as 130. To convert to Fabric, we simply need
 * to divide by 113.
 * @param value
 * @returns {number}
 */
export const validateLineHeight = (value: number) => {
  return value / lineHeightDfrncFactor;
};

/**
 * Reverse function for validateLineHeight
 * @param {number} value
 * @return {number}
 */
export const invalidateLineHeight = (value: number) => {
  return lineHeightDfrncFactor * value;
};

/**
 * Replaces /\r/g with \r\n as JavaScript cannot handle a single \r character
 * (which was the default on Macs pre-OSX).
 * @param text
 * @returns {void|XML|string|*}
 */
// TODO: Move this logic to php
export const validateText = (text: string) => {
  return text.replace(/\r/g, '\n').replace(/\u2028/g, '');
};

/**
 * Used for table/tabs mediator to get text background color for a view (fabric.Text or subclass), using the passed modal.
 */
export const getBackgroundColor = (model: TextItem | TableItem | TabsItem): string | undefined => {
  if (model.backgroundType === 1) {
    return rgbaToHexString(model.backgroundColor);
  }
  return undefined;
};

// TODO: Add the other item types for param model when they are implemented.
export const getBoldStrokeWidth = (model: TextItem) => {
  // if (model.isTab()) {
  //     return BOLD_STROKE_WIDTH_FACTOR;
  // }
  return BOLD_STROKE_WIDTH_FACTOR * model.textStyles.fontSize;
};
