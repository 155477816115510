import type {MouseEventHandler, ReactElement} from 'react';
import React, {useRef, useState} from 'react';
import {playVideo} from '@Utils/video.util';
import {numberToMMSSFormat} from '@Utils/date.util';
import type {GridMediaItemTag} from '@Components/base-grid/components/grid-media-item';
import {GridMediaItemTagType} from '@Components/base-grid/components/grid-media-item';
import {CostTag} from '@Components/cost-tag';
import {SourceTag} from '@Components/source-tag';
import styles from './video-preview.module.scss';
import {isMobile} from 'react-device-detect';
import {noop} from '@Utils/general.util';
import {VideoOverlayPreview} from '@Components/video-preview-new/components/video-overlay-preview';
import useLongPress from '@Hooks/useLongPress';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';

interface VideoPreviewProps {
  imgPlaceHolderSrc: string;
  videoSrc?: string;
  className?: string;
  duration?: number;
  showVideoPreview?: boolean;
  showUHDIcon?: boolean;
  muted?: boolean;
  loadingIcon?: string;
  isSmallItem?: boolean;
  tag?: GridMediaItemTag;
  showUnmute?: boolean;
  showDurationTag?: boolean;
  showVideoTag?: boolean;
}

export default function VideoPreview({
  showUHDIcon = false,
  showDurationTag = true,
  className = '',
  showUnmute = false,
  isSmallItem = false,
  tag = undefined,
  showVideoPreview = true,
  showVideoTag = false,
  ...props
}: VideoPreviewProps): ReactElement {
  const [videoPreviewReady, setVideoPreviewReady] = useState(true);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [muted, setMuted] = useState(props.muted ?? true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [seekTime, setSeekTime] = useState(props.duration ?? 0);
  const [showMobileHoverPreview, setShowMobileHoverPreview] = useState(false);
  const longPressHandler = useLongPress(() => {
    setShowMobileHoverPreview(isMobile);
  }, 500);

  const videoElement = useRef<HTMLVideoElement>(null);

  const handleSeekTimeUpdate = (): void => {
    if (videoElement.current?.currentTime) {
      setSeekTime(Math.floor(videoElement.current.currentTime));
    }
  };

  const toggleMute = (): void => {
    setMuted((prevMuted) => !prevMuted);
  };

  const showLoading = (): void => {
    setLoadingVideo(true);
  };

  const hideLoading = (): void => {
    setLoadingVideo(false);
  };

  const onPlay = (): void => {
    setIsVideoPlaying(true);
  };

  const onPause = (): void => {
    setIsVideoPlaying(false);
  };

  const onPlayError = (): void => {
    setIsVideoPlaying(false);
    setVideoPreviewReady(false);
    setLoadingVideo(false);
  };

  const onMouseEnter = async (): Promise<void> => {
    setOnMouseOver(true);
    if (videoPreviewReady && videoElement.current && !isMobile) {
      await playVideo(videoElement.current);
    }
  };

  const onMouseLeave = (): void => {
    longPressHandler.onMouseLeave();
    setOnMouseOver(false);
    if (props.duration) {
      setSeekTime(props.duration);
    }

    if (videoElement.current) {
      videoElement.current.pause();
      if (props.duration) {
        videoElement.current.currentTime = props.duration;
      }
    }
  };

  const getDurationPill = (): ReactElement => {
    return (
      <span className={`${styles.streamMediaLength} ${isSmallItem ? 'body-xxs-bold' : 'body-s-bold'} ${loadingVideo ? styles.blink : ''}`}>
        {showUHDIcon ? `${numberToMMSSFormat(seekTime)} · ${window.i18next.t('pmwjs_4k')}` : numberToMMSSFormat(seekTime)}
      </span>
    );
  };

  const getVideoTag = (): ReactElement | null => {
    if (showVideoTag) {
      return (
        <div className={`${styles.videoTagIcon} radius-4 flex-center`}>
          <Icon className="content-body-white" icon="icon-videocam" size={IconSize.SIZE_ICON_16} />
        </div>
      );
    }
    return null;
  };

  const getTag = (): ReactElement | null => {
    if (tag) {
      return (
        <div className={`${styles.tagContainer} _full-width _full-height`}>
          {tag.type === GridMediaItemTagType.COST ? (
            <CostTag containerStyle={tag.containerStyle} textStyle={tag.textStyle} icon={tag.icon} price={tag.price} />
          ) : (
            <SourceTag containerStyle={tag.containerStyle} contentStyle={tag.iconStyle} />
          )}
        </div>
      );
    }
    return null;
  };

  const getVideoPreviewOverlayForMobile = (): ReactElement | null => {
    if (isMobile && props.videoSrc && props.duration && showVideoPreview) {
      return (
        <VideoOverlayPreview
          source={props.videoSrc}
          duration={props.duration}
          onClose={() => {
            setShowMobileHoverPreview(false);
            onMouseLeave();
          }}
        />
      );
    }
    return null;
  };

  const onMobileContextMenu: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div
      className={`${styles.videoPreview} ${className}`}
      {...longPressHandler}
      onMouseEnter={() => {
        void onMouseEnter();
      }}
      onMouseLeave={onMouseLeave}
      onContextMenu={isMobile ? onMobileContextMenu : noop}
    >
      <img
        onContextMenu={isMobile ? onMobileContextMenu : noop}
        className={`${styles.imgThumb} radius-12`}
        src={props.imgPlaceHolderSrc}
        alt={window.i18next.t('pmwjs_image')}
        draggable="false"
        loading="lazy"
      />
      <video
        className={`${styles.video} radius-12 ${isVideoPlaying ? '' : '_hidden'}`}
        onTimeUpdate={handleSeekTimeUpdate}
        onPlay={onPlay}
        onPause={onPause}
        onError={onPlayError}
        onAbort={onPlayError}
        onWaiting={showLoading}
        onPlaying={hideLoading}
        onCanPlayThrough={hideLoading}
        crossOrigin="anonymous"
        draggable="false"
        muted={muted}
        loop
        playsInline
        src={props.videoSrc}
        preload="none"
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
        ref={videoElement}
      />
      {showUnmute ? (
        <i className={`${styles.muteVideoButton} icon-sound ${muted ? styles.muted : ''}`} title={window.i18next.t(muted ? 'pmwjs_unmute' : 'pmwjs_unmute')} onClick={toggleMute} />
      ) : null}
      {!videoPreviewReady && onMouseOver ? <div className={`${styles.previewNotReady} body-xs-bold radius-12`}>{window.i18next.t('pmwjs_preview_not_ready')}</div> : null}
      {seekTime !== undefined && showDurationTag ? getDurationPill() : null}
      {tag ? getTag() : null}
      {getVideoTag()}
      {showMobileHoverPreview ? getVideoPreviewOverlayForMobile() : null}
    </div>
  );
}
