import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import Slider from '@postermywall/rc-slider';
import './controlled-slider.scss';
import 'rc-slider/assets/index.css';
import type {ControlledSliderHandlers, ControlledSliderProperties} from '@Components/controlled-slider/controlled-slider.types';
import {isMobile} from 'react-device-detect';
import {Text, TextSize} from '@Components/text';
import Tooltip from 'react-bootstrap/Tooltip';
import {formatTimeToDisplayFormat} from '@Utils/date.util';
import {v4 as uuidv4} from 'uuid';
import {AnimatePresence, motion} from 'framer-motion';
import styles from './controlled-slider.module.scss';

interface ControlledSliderProps extends ControlledSliderHandlers, ControlledSliderProperties {
  className?: string;
  ariaLabel?: string;
  value: number;
  handleStyle?: React.CSSProperties | React.CSSProperties[];
}

export function ControlledSlider({
  className = '',
  ariaLabel = '',
  min = 0,
  max = 100,
  useMarksAsSteps = false,
  marks = [],
  step = 1,
  onSlideEnded = (): void => {},
  isDisabled = false,
  showTooltipOnDrag = false,
  addDelayToChange = false,
  showSeekDividerWithHandle = false,
  seekDividerClassName = '',
  dividerHeight = undefined,
  ...props
}: ControlledSliderProps): ReactElement {
  const id = uuidv4();
  const jsClassName = useRef(id);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const attachLeftArrowClass = useRef<boolean>(false);
  const attachRightArrowClass = useRef<boolean>(false);
  const [tempVal, setTempVal] = useState<number>(props.value);
  const timer = useRef<number | undefined>();
  const lastOnChangeEndedValue = useRef<number | undefined>();

  useEffect((): void => {
    setTempVal(props.value);
  }, [props.value]);

  const onPointerDownOnSlider = (): void => {
    document.body.classList.add(styles.cursorPointer);
  };

  const onPointerUpOnSlider = (): void => {
    document.body.classList.remove(styles.cursorPointer);
  };

  useEffect(() => {
    const slider = document.querySelector(`.${getUniqueClassName()}`);

    slider?.addEventListener('pointerdown', onPointerDownOnSlider);
    window.addEventListener('pointerup', onPointerUpOnSlider);

    return () => {
      slider?.removeEventListener('pointerdown', onPointerDownOnSlider);
      window.removeEventListener('pointerup', onPointerUpOnSlider);
    };
  }, []);

  const getMarks = (): Record<number, string> => {
    const marksObject: Record<number, string> = {};
    if (marks && marks.length > 0) {
      marks.map((mark) => {
        marksObject[mark] = '.';
        return marksObject;
      });
      return marksObject;
    }
    return marksObject;
  };

  const getTooltipLeftOffset = (): number => {
    let defaultLeft = 21;

    if (tooltipRef) {
      defaultLeft = tooltipRef.clientWidth / 2;
    }

    return defaultLeft;
  };

  const getTooltipTopOffset = (): number => {
    let defaultTop = -54;
    const arrowHeight = 16;
    if (tooltipRef) {
      defaultTop = (tooltipRef.clientHeight + arrowHeight) * -1;
    }

    return defaultTop;
  };

  const getDefaultWidthForTooltip = (): number => {
    if (tempVal > 60) {
      return 75;
    }

    return 43;
  };

  const getTooltipLeft = (handleLeft?: string | number): string => {
    const tooltipOffsetToAdd = getTooltipLeftOffset();
    const leftInNumber = Number(String(handleLeft).replace('%', ''));

    if (leftInNumber < 5) {
      attachLeftArrowClass.current = true;
      return `calc(${handleLeft} - 10px)`;
    }
    attachLeftArrowClass.current = false;

    if (leftInNumber > 95) {
      attachRightArrowClass.current = true;
      return `calc(${handleLeft} - ${tooltipRef?.clientWidth ?? getDefaultWidthForTooltip()}px + 10px)`;
    }
    attachRightArrowClass.current = false;

    return `calc(${handleLeft} - ${tooltipOffsetToAdd}px)`;
  };

  const getUniqueClassName = (): string => {
    return `js-${jsClassName.current}`;
  };

  const getSeekHandleDivider = (handleLeft?: string | number): ReactElement | null => {
    return (
      <AnimatePresence>
        {showSeekDividerWithHandle ? (
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5}} exit={{opacity: 0}}>
            <div
              style={{
                height: `${dividerHeight}px`,
                left: `calc(${handleLeft} - 2px)`,
              }}
              className={`${styles.seekContainer} ${seekDividerClassName} `}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="5" height={`${dividerHeight}px`} fill="none">
                <rect width="5" height={`${dividerHeight}px`} fill="white" />
                <rect x="1.75" y="0.5" width="1.5" height={`${dividerHeight}px`} fill="#2C2B44" />
              </svg>{' '}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  };

  const getTooltipForHandle = (isDragging: boolean, handleLeft?: string | number): ReactElement | null => {
    if (!isDragging) {
      return null;
    }

    return (
      <Tooltip
        ref={setTooltipRef}
        className={`${styles.tooltip} ${attachLeftArrowClass.current ? styles.leftArrowTooltip : ''} ${attachRightArrowClass.current ? styles.rightArrowTooltip : ''}`}
        id="handle-tooltip"
        style={{
          left: getTooltipLeft(handleLeft),
          top: `${getTooltipTopOffset()}px`,
        }}
      >
        <Text className={styles.tooltipText} val={formatTimeToDisplayFormat(addDelayToChange ? tempVal : props.value)} size={TextSize.XSMALL} />
      </Tooltip>
    );
  };

  return (
    <Slider
      handleRender={(handleProps, renderProps): ReactElement => {
        if (!showTooltipOnDrag) {
          return handleProps;
        }
        const {dragging} = renderProps;

        return (
          <>
            {handleProps}
            {getSeekHandleDivider(handleProps.props.style?.left)}
            {getTooltipForHandle(dragging, handleProps.props.style?.left)}
          </>
        );
      }}
      className={`controlled-slider ${getUniqueClassName()} ${isMobile ? 'mobile' : ''} ${className}`}
      marks={getMarks()}
      step={useMarksAsSteps && marks?.length > 0 ? null : step}
      ariaLabelForHandle={ariaLabel}
      min={min}
      max={max}
      value={addDelayToChange ? tempVal : props.value}
      onChange={(value): void => {
        setTempVal(value as number);
        if (!addDelayToChange) {
          if (props.value === value) {
            return;
          }
          props.onSlide(value as number);
          return;
        }

        if (timer) {
          clearTimeout(timer.current);
        }

        timer.current = window.setTimeout(() => {
          props.onSlide(value as number);
        }, 200);
      }}
      onAfterChange={(value): void => {
        if (props.value === value || lastOnChangeEndedValue.current === value) {
          return;
        }
        lastOnChangeEndedValue.current = value as number;
        onSlideEnded(value as number);
      }}
      disabled={isDisabled}
    />
  );
}
