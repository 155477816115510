import type {ReactElement} from 'react';
import React from 'react';
import type {GridItemProps} from '@Components/base-grid/components/grid-item';
import {GridMediaItem} from '@Components/base-grid/components/grid-media-item';
import VideoPreview from '@Components/video-preview-new';

interface GridVideoItemProps extends GridItemProps {
  imgThumbSrc: string;
  videoThumbSrc: string;
  duration?: number;
  isLoading?: boolean;
  showDurationTag?: boolean;
  showVideoTag?: boolean;
}

export function GridVideoItem({...props}: GridVideoItemProps): ReactElement {
  return (
    <GridMediaItem
      {...props}
      className={props.className}
      thumb={
        <VideoPreview
          showVideoPreview={false}
          showDurationTag={props.showDurationTag}
          isSmallItem={props.isSmallItem}
          imgPlaceHolderSrc={props.imgThumbSrc}
          videoSrc={props.videoThumbSrc}
          duration={props.duration}
          showUHDIcon={false}
          showVideoTag={props.showVideoTag}
        />
      }
    />
  );
}
