import type {ReactElement} from 'react';
import React, {useState} from 'react';
import type {ImageThumbnailProps} from './image-thumbnail.types';
import {SkeletonBrick} from '@Components/skeleton-brick';
import {noop} from '@Utils/general.util';

export function ImageThumbnail({onClick = noop, ...props}: ImageThumbnailProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const getSkeletonBrickForLoadingState = (): ReactElement | null => {
    if (!props.data.showSkeletonLoading) {
      return null;
    }

    return <SkeletonBrick theme={props.data.skeletonLoadingTheme} className={`_position-absolute ${props.className ?? ''}`} />;
  };

  return (
    <div className={'_position-relative flex-center'}>
      {isImageLoaded ? null : getSkeletonBrickForLoadingState()}

      <img
        onLoad={(): void => {
          setIsImageLoaded(true);
        }}
        onClick={onClick}
        className={props.className}
        src={props.data.imageSrc}
        alt={window.i18next.t('pmwjs_image_preview')}
      />
    </div>
  );
}
