

/**
 * A generic error handler that opens an error modal with a generic error message and sends the error log to the server.
 * @param error - The error object
 * @param message
 */
export const onAjaxError = (error: unknown, message?: string, logError = true): void => {
  window.PMW.openErrorModal({
    message: message ? message : window.i18next.t('pmwjs_error_message'),
    log: logError,
  });
  console.error(error);
  if (logError) {
    window.PMW.log(getErrorToLogOnServer(error) ?? message);
  }
};

const getErrorToLogOnServer = (error: unknown): string | null => {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (error !== null && typeof error === 'object' && Object.prototype.hasOwnProperty.call(error, 'message')) {
    return (error as {message: string}).message;
  }

  return null;
}

export interface AjaxSuccessResponse {
  status: 'success' | 'fail' | 'error';
  data: any;
}

export interface AjaxResponse {
  error?: AjaxError;
  data?: any;
  meta?: {
    requestId: string;
  };
}

export interface AjaxError {
  error: {
    name: string;
    message: string;
    stack: string;
  };
}
