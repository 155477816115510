import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {EventsHomePageMetaBackendResponse, EventsHomeReduxState, UserEventsScheduleVO} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {getEventsScheduleInfoForUser} from '@Components/mystuff-events-home/mystuff-events-home-thunk';
import {LoadingStates} from '@Utils/loading.util';
import {PMW_COLORS_PRIMARY} from '@Utils/color.util';
import {EventsHomePageLayout} from '@Components/mystuff-events-home/mystuff-events-home.types';
import type {SocialMediaProfileLinksMap} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {mapUserEventsScheduleVOToFormState} from "@Components/mystuff-events-home/mystuff-events-home-slice";

export const mystuffEventsHomeExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<EventsHomeReduxState>>): void => {
  builder.addCase(getEventsScheduleInfoForUser.pending, (state) => {
    state.loadingState = LoadingStates.LOADING;
  });

  builder.addCase(getEventsScheduleInfoForUser.fulfilled, (state, {payload}) => {
    const userEventsScheduleVO = payload.userEventsSchedule;
    state.loadingState = LoadingStates.LOADED;
    if (!userEventsScheduleVO) {
      state.isEmptyState = true;
      return;
    }

    state.eventsHomeHashedId = userEventsScheduleVO.hashedID;
    mapUserEventsScheduleVOToFormState(state, userEventsScheduleVO);
  //   state.pageDetailsFormData = {
  //     title: userEventsScheduleVO.title,
  //     description: userEventsScheduleVO.description ?? '',
  //     doShowPastEvents: userEventsScheduleVO.doShowPastEvents ?? true,
  //     doRemoveWatermark: userEventsScheduleVO.doRemoveWatermark ?? false,
  //     imageURL: userEventsScheduleVO.profilePictureURL,
  //     coverPhotoURL: userEventsScheduleVO.coverPhotoURL,
  //     fontFamily: userEventsScheduleVO.fontFamily ?? 'RakkasRegular',
  //     color: userEventsScheduleVO.primaryColor ?? PMW_COLORS_PRIMARY.PRIMARY_100,
  //     eventsHomeLayoutPreference: userEventsScheduleVO.viewType ? (userEventsScheduleVO.viewType as EventsHomePageLayout) : EventsHomePageLayout.LIST,
  //   };
  //
  //   state.contactDetailsFormData = {
  //     email: userEventsScheduleVO.email,
  //     phoneNumber: userEventsScheduleVO.phoneNumber ?? '',
  //     socialMediaProfilesInput: getSocialProfileLinksFromPayload(userEventsScheduleVO),
  //   };
    state.recentEmailsUsed = payload.emailSuggestions;
    state.recentPhoneNumbersUsed = payload.phoneNumberSuggestions;
    state.savedEventsScheduleVO = userEventsScheduleVO;
  });

  builder.addCase(getEventsScheduleInfoForUser.rejected, (state) => {
    state.loadingState = LoadingStates.LOADED;
  });
};
