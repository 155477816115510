import {submitAuthForm} from '@Components/login-page/login-page-thunk';
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {NoInfer} from '@reduxjs/toolkit/dist/tsHelpers';
import type {AsyncStudentLoginErrorResponse, LoginErrorResponse, LoginPageState, LoginSuccessResponse} from '@Components/login-page/login-page.types';
import {AUTH_MODE, AUTH_TYPE, CONFIRM_LOGIN_URL, hideErrors, redirectToPage, showErrors} from '@Libraries/login-signup-library';

const USER_BLOCKED = 'user_blocked';

enum AsyncStudentLoginErrorType {
  NOT_FOUND = 'notfound',
  EMPTY = 'empty',
}

export const loginPageExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<LoginPageState>>): void => {
  builder.addCase(submitAuthForm.pending, (state) => {
    enableLoadingState(state);
  });

  builder.addCase(submitAuthForm.fulfilled, (state, {payload}) => {
    if (shouldButtonLoadingBeDisabled(state)) {
      disableLoadingState(state);
    }
    hideErrors(state);
    onSubmitSuccess(state, payload);
  });

  builder.addCase(submitAuthForm.rejected, (state, {payload}) => {
    disableLoadingState(state);
    onSubmitRejected(state, payload);
  });
};

const enableLoadingState = (state: LoginPageState): void => {
  state.isLoading = true;
};

const disableLoadingState = (state: LoginPageState): void => {
  state.isLoading = false;
};

const onSubmitSuccess = (state: LoginPageState, payload: LoginSuccessResponse): void => {
  switch (state.authType) {
    case AUTH_TYPE.LOGIN:
    case AUTH_TYPE.STUDENT_LOGIN:
    case AUTH_TYPE.CONFIRM_LOGIN:
      handleLoginSubmitSuccess(state, payload);
      break;
    default:
      break;
  }
};

const handleLoginSubmitSuccess = (state: LoginPageState, payload: LoginSuccessResponse): void => {
  if (state.authMode === AUTH_MODE.ASYNC) {
    return;
  }

  if (state.isConnectSSOAccountModal) {
    disableLoadingState(state);
    return;
  }

  if (payload.pendingMFA) {
    disableLoadingState(state);
    state.redirectUrl = payload.redirectURL;
    return;
  }

  if (payload.redirectURL === CONFIRM_LOGIN_URL) {
    if (state.authType === AUTH_TYPE.LOGIN) {
      state.confirmLoginType = AUTH_TYPE.LOGIN;
      state.encryptedEmail = payload.email;
      state.encryptedPassword = payload.password;
    } else {
      state.confirmLoginType = AUTH_TYPE.STUDENT_LOGIN;
      state.projectName = payload.project;
    }
    state.authType = AUTH_TYPE.CONFIRM_LOGIN;
    return;
  }

  if (window.PMW.util.doesUrlContainEmbeddedEditorAuthenticationLayoverUri(payload.redirectURL ?? '')) {
    window.close();
    return;
  }

  redirectToPage(payload.redirectURL);
};

const onSubmitRejected = (state: LoginPageState, payload: any): void => {
  state.isLoginToProceedMessage = false;
  switch (state.authType) {
    case AUTH_TYPE.STUDENT_LOGIN:
      handleStudentLoginError(state, payload);
      break;
    case AUTH_TYPE.CONFIRM_LOGIN:
      handleConfirmLoginError(state, payload);
      break;
    case AUTH_TYPE.LOGIN:
    default:
      handleLoginError(state, payload);
      break;
  }
};

const handleLoginError = (state: LoginPageState, payload: LoginErrorResponse): void => {
  if (state.authMode === AUTH_MODE.ASYNC) {
    return;
  }

  if (!payload.data || !payload.data.reason) {
    showErrors(state, window.i18next.t('pmwjs_something_went_wrong_contact_support'));
    return;
  }

  switch (payload.data.reason) {
    case USER_BLOCKED:
      redirectToPage(window.PMW.util.site_url(payload.data?.redirectURL));
      break;
    default:
      showErrors(state, payload.data.message ?? '');
  }
};

const handleStudentLoginError = (state: LoginPageState, payload: any): void => {
  switch (state.authMode) {
    case AUTH_MODE.ASYNC:
      handleAsyncStudentLoginError(state, payload as AsyncStudentLoginErrorResponse);
      break;
    case AUTH_MODE.DEFAULT:
    default:
      handleLoginError(state, payload);
  }
};

const handleAsyncStudentLoginError = (state: LoginPageState, payload: AsyncStudentLoginErrorResponse): void => {
  let errorMessage = '';
  const status = payload.data?.status;

  if (status === AsyncStudentLoginErrorType.NOT_FOUND) {
    errorMessage = window.i18next.t('pmwjs_project_not_found');
  } else if (status === AsyncStudentLoginErrorType.EMPTY) {
    errorMessage = window.i18next.t('pmwjs_project_name_required');
  } else {
    errorMessage = window.i18next.t('pmwjs_unknown_error');
  }

  if (errorMessage !== '') {
    showErrors(state, errorMessage);
  }
};

const handleConfirmLoginError = (state: LoginPageState, payload: LoginErrorResponse): void => {
  if (payload.status === 'error') {
    redirectToPage(window.PMW.util.site_url(`${payload.data?.redirectUrl}?redirecturl=${state.redirectUrl}`));
    return;
  }

  handleLoginError(state, payload);

  if (payload.data?.reason !== USER_BLOCKED) {
    state.authType = state.confirmLoginType;
  }
};

const shouldButtonLoadingBeDisabled = (state: LoginPageState): boolean => {
  return state.authMode === AUTH_MODE.ASYNC || state.authType === AUTH_TYPE.FORGOT_PASSWORD;
};
