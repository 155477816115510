import type {ReactElement} from 'react';
import React from 'react';
import type {GridMediaItemStorage, GridMediaItemTag} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';
import type {GridItemProps} from '../grid-item';
import VideoPreview from '@Components/video-preview-new/video-preview';
import {isMobile} from 'react-device-detect';
import styles from './grid-stock-video-item.module.scss';

export interface GridStockVideoItemStorage extends GridMediaItemStorage {
  videoThumbSrc?: string;
  imgThumbSrc?: string;
  isUHD?: boolean;
  duration?: string;
  source: string;
  data: Record<string, any>;
}

export interface GridStockVideoItemBackEndData {
  [key: string]: any;
}

interface GridStockVideoItemProps extends GridItemProps {
  videoThumbSrc?: string;
  imgThumbSrc?: string;
  isUHD?: boolean;
  duration?: number;
  tag?: GridMediaItemTag;
  isLoading?: boolean;
}

export function GridStockVideoItem({videoThumbSrc = '', imgThumbSrc = '', isUHD = false, ...props}: GridStockVideoItemProps): ReactElement {
  return (
    <GridMediaItem
      {...props}
      containerClassName={`${props.containerClassName} ${isMobile ? styles.mobileVideoItem : ''}`}
      paddedThumb={false}
      thumb={
        <VideoPreview
          tag={props.tag}
          isSmallItem={props.isSmallItem}
          imgPlaceHolderSrc={imgThumbSrc}
          videoSrc={videoThumbSrc}
          duration={Number(props.duration)}
          showUHDIcon={isUHD}
        />
      }
    />
  );
}
