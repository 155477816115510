import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '../icon-v2';
import type {ThumbnailProps} from '@Components/thumbnail/thumbnail.types';
import {ThumbnailType} from '@Components/thumbnail/thumbnail.types';
import {ImageThumbnail} from '@Components/thumbnail/components/image-thumbnail/image-thumbnail';

export function Thumbnail({className = '', onClick = (): void => {}, ...props}: ThumbnailProps): ReactElement {
  const displayThumbnail = (): ReactElement | null => {
    switch (props.data.type) {
      case ThumbnailType.ICON:
        return (
          <Icon
            onClick={onClick}
            className={className}
            isDisabled={props.data.disabled}
            icon={props.data.icon}
            type={props.data.icon_type}
            size={props.data.icon_size}
            shape={props.data.IconShape}
            flatIconType={props.data.flat_icon_type}
          />
        );
      case ThumbnailType.IMAGE:
        return <ImageThumbnail data={props.data} className={className} onClick={onClick} />;
      case ThumbnailType.CUSTOM:
        return <div className={className}>{props.data.content}</div>;
      default:
        return null;
    }
  };

  return <>{displayThumbnail()}</>;
}
