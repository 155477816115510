import type {PointerEvent, ReactElement} from 'react';
import React from 'react';
import type {InputFieldControlItemData, InputFieldControlItemEvent} from '@Components/input-field-v2/input-field.types';
import {InputControlItemType, InputFieldSize} from '@Components/input-field-v2/input-field.types';
import {Text, TextSize} from '@Components/text';
import {Icon} from '@Components/icon-v2/icon';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './input-field-control-item.module.scss';

interface InputFieldControlItemProps {
  data: InputFieldControlItemData;
  isDisabled?: boolean;
  isRightMostIcon?: boolean;
  size?: InputFieldSize;
  onClick?: (id: string, e?: InputFieldControlItemEvent) => void;
  doBlurInputOnClick?: boolean;
}

export function InputFieldControlItem({
  size = InputFieldSize.SMALL,
  isRightMostIcon = false,
  isDisabled = false,
  onClick = undefined,
  doBlurInputOnClick = true,
  ...props
}: InputFieldControlItemProps): ReactElement | null {
  const getTextClasses = (): string => {
    const classes = [props.data.className, 'content-disabled', styles.text];

    return classes.join(' ');
  };

  const getIconClasses = (): string => {
    const classes = [props.data.className];

    if (props.data.type === InputControlItemType.INACTIVE_ICON) {
      classes.push(styles.inActiveIcon);
    }

    if (isDisabled) {
      classes.push(styles.disabledIcon);
    }

    return classes.join(' ');
  };

  const getContainerClasses = (): string => {
    const classes = ['flex-center', 'spacing-p-0', styles.controlItemContainer, props.data.containerClassName ?? ''];

    if (isRightMostIcon) {
      classes.push(styles.borderRight);
    }

    if (props.data.type === InputControlItemType.ICON_BTNS) {
      classes.push(styles.iconBtn);
    }

    if (props.data.type === InputControlItemType.ICON_BTNS && props.data.isBorderLess) {
      classes.push(styles.leftBorderNone);
    }

    if (props.data.type === InputControlItemType.INACTIVE_TEXT || props.data.type === InputControlItemType.INACTIVE_ICON) {
      classes.push(styles.inActiveContent);
    } else {
      classes.push(styles.activeContent);
    }

    switch (size) {
      case InputFieldSize.XSMALL:
        if (isRightMostIcon) {
          classes.push(styles.xsmallRightMost);
        }
        classes.push(styles.xsmall);
        break;
      case InputFieldSize.SMALL:
        if (isRightMostIcon) {
          classes.push(styles.smallRightMost);
        }
        classes.push(styles.small);
        break;
      case InputFieldSize.MEDIUM:
        if (isRightMostIcon) {
          classes.push(styles.mediumRightMost);
        }
        classes.push(styles.medium);
        break;
      default:
        classes.push(styles.small);
    }

    if (isDisabled) {
      classes.push(styles.disabled);
    }

    return classes.join(' ');
  };

  const onClickHandler = (e: InputFieldControlItemEvent): void => {
    if (props.data.type === InputControlItemType.ICONS_ONLY || props.data.type === InputControlItemType.ICON_BTNS) {
      if (onClick) {
        onClick(props.data.id, e);
      }
    }
  };

  const getIconSize = (): IconSize => {
    switch (size) {
      case InputFieldSize.XSMALL:
        return IconSize.SIZE_ICON_16;
      case InputFieldSize.SMALL:
        return IconSize.SIZE_ICON_16;
      case InputFieldSize.MEDIUM:
        return IconSize.SIZE_ICON_20;
      default:
        return IconSize.SIZE_ICON_16;
    }
  };

  const getTextSize = (): TextSize => {
    switch (size) {
      case InputFieldSize.XSMALL:
        return TextSize.XXSMALL;
      case InputFieldSize.SMALL:
        return TextSize.XSMALL;
      case InputFieldSize.MEDIUM:
        return TextSize.SMALL;
      default:
        return TextSize.XSMALL;
    }
  };

  const prepareControlItem = (): ReactElement => {
    switch (props.data.type) {
      case InputControlItemType.INACTIVE_TEXT:
        return <Text val={props.data.text} size={getTextSize()} className={getTextClasses()} />;
      case InputControlItemType.ICON_BTNS:
        return <Icon icon={props.data.icon} size={getIconSize()} className={getIconClasses()} isLoading={props.data.isLoading} isDisabled={isDisabled} />;
      case InputControlItemType.INACTIVE_ICON:
        return <Icon icon={props.data.icon} size={getIconSize()} className={getIconClasses()} isLoading={props.data.isLoading} isDisabled={isDisabled} />;
      case InputControlItemType.ICONS_ONLY:
        return <Icon icon={props.data.icon} size={getIconSize()} className={getIconClasses()} isLoading={props.data.isLoading} isDisabled={isDisabled} />;
      default:
        throw new Error('Invalid type passed to control item');
    }
  };

  const onPointerDown = (event: PointerEvent): void => {
    if (!doBlurInputOnClick) {
      event.preventDefault();
    }
  };

  return props.data ? (
    <li onPointerDown={onPointerDown} onKeyDown={onClickHandler} onClick={onClickHandler} className={getContainerClasses()}>
      {prepareControlItem()}
    </li>
  ) : null;
}
