import React, {useState, useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import './popup-menu.scss';
import {PopupToggleButton} from "./components";

/**
 * default vertical offset in pixels between popup menu and its toggle button
 * @type {number}
 */
const MENU_DEFAULT_VERTICAL_OFFSET = 8;
/**
 * the space b/w popup and its toggle button and the popup is above the button (inverted)
 * @type {number}
 */
const MENU_VERTICAL_OFFSET_INVERTED = 24;
/**
 * the percentage of the max available height that the popup menu will take when its height needs to be capped due to overflow
 * for example, the menu is vertically overflowing its parent, so the new height will be 90% of the max available height
 * @type {number}
 */
const CAPPED_MENU_MAX_HEIGHT_PERCENTAGE = 90;
/**
 * Apply this class to an ancestor container of the popup if we want to invert the popup if there is less vertical space below,
 * we do not want the popup opening to cause vertical overflow without using overflow: hidden in css
 * @type {string}
 */
export const INVERT_POPUP_ON_OVERFLOW_CLASS = 'js-invert-popup-on-overflow';
export const POPUP_TOGGLE_ITEM_CLASS = 'js-pop-menu-toggle-item';

/**
 * A menu component that is toggled by a button and is positioned relative to that button's placement on the DOM
 * The component is a wrapper component and the actual menu-items are passed down as props. This component is responsible for the opening, closing, and positioning of the menu
 * A PopupMenuItem component is available which may be enough for most cases, it is simply a tile containing an icon and some text
 * A PopupToggleButton component is also available as a simple Icon button with three dots in the middle. This button is set as the default, so no need to pass a menuToggleButton in case of re-using this button.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 */
const PopupMenu = ({menuToggleButton, classes, menuClasses, menuverticalOffset, maxWidth, withArrow, openModalOnPhone, modalTitle, leftFixed, bottomFixed, scrollingContainerSelector, children, doCloseOnClick, popupHasSpacing = false}) => {
    const menuRef = useRef(null),
        [isOpen, setIsOpen] = useState(false);

    const positionMenu = () => {
        let popupMenu = $(menuRef.current),
            toggleBtn = popupMenu.prev('.js-popup-menu-toggle-btn'),
            params = {
                'toggleBtn': toggleBtn,
                'popupMenu': popupMenu,
                'menuverticalOffset': menuverticalOffset,
                'leftFixed': leftFixed,
                'bottomFixed': bottomFixed,
                'scrollingContainerSelector': scrollingContainerSelector
            };

        toggleBtn.addClass('-open');
        calculateAndSetPopupMenuPosition(params);
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        const menu = (
            <ul className={`popup-menu ${menuClasses}`}>
                {children}
            </ul>
        );
        if (PMW.isMobileScreenWidth() && openModalOnPhone) {
            PMW.openMenuListModal({
                modalTitle: modalTitle,
                modalClasses: `popup-menu-modal ${menuClasses}`,
                modalContent: menu,
                closeOnModalBodyClick: true
            })
        } else {
            setIsOpen(prevState => !prevState);
        }
    }

    /**
     * check if the passed element is this component instance's toggle button
     * @param {HTMLElement} elementToTest
     * @return {boolean}
     */
    const isElementPopupToggleItem = (elementToTest) => {
        return elementToTest.classList.contains(POPUP_TOGGLE_ITEM_CLASS) || elementToTest.closest(`.${POPUP_TOGGLE_ITEM_CLASS}`);
    }

    /**
     * check if the passed element is this component instance's toggle button
     * @param {HTMLElement} elementToTest
     * @return {boolean}
     */
    const isElementToggleButton = (elementToTest) => {
        if (menuRef.current) {
            const toggleBtnContainer = menuRef.current.previousSibling;
            return toggleBtnContainer.classList.contains('js-popup-menu-toggle-btn') ? toggleBtnContainer.contains(elementToTest): false;
        }
        return false;
    }

    /**
     * close menu if click came from outside the menu and was not THIS menu's toggle button
     * @param {Event} e
     */
    const handleDocumentClick = (e) => {
        if (!isElementToggleButton(e.target) && !isElementPopupToggleItem(e.target) && doCloseOnClick) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isOpen) {
            positionMenu();
            document.addEventListener('click', handleDocumentClick);

            //cleanup, remove event handler when component unmounts
            return () => {
                document.removeEventListener('click', handleDocumentClick);
            }
        }

    }, [isOpen]);

    const menuButton = React.cloneElement(menuToggleButton, {
            ...menuToggleButton.props,
            onClick: handleButtonClick,
            className: `${menuToggleButton.props.className} js-popup-menu-toggle-btn popup-menu-toggle-btn ${isOpen ? '-open' : ''}`
        }),
        inlineStyles = {style: {maxWidth: `${maxWidth}px`}};

    return (
        <>
            {menuButton}
            {isOpen &&
                <div className={`popup-menu-container ${withArrow ? '-with-arrow' : ''} ${classes}`} ref={menuRef} {...maxWidth && inlineStyles} >
                    <ul className={`popup-menu js-popup-menu ${menuClasses} ${popupHasSpacing ? 'spacing-p-3': ''}`}>
                        {children}
                    </ul>
                </div>
            }
        </>
    );
}


/**
 * calculates and sets the appropriate positioning for the popup menu
 * @param {object} params
 * @param {jQuery} params.toggleBtn
 * @param {jQuery} params.popupMenu
 * @param {number} params.menuverticalOffset the vertical offset to add between the toggle button and menu
 * @param {boolean} params.leftFixed
 * @param {boolean} params.bottomFixed
 * @param {string} params.scrollingContainerSelector
 */
const calculateAndSetPopupMenuPosition = (params) => {
    let offset,
        docOffset = params.toggleBtn.offset(),
        left,
        top,
        parent = params.popupMenu.offsetParent(),
        menuClasses = '';

    if (parent.is('body')) {
        left = docOffset.left;
        top = docOffset.top + params.toggleBtn.outerHeight();
    } else {
        offset = params.toggleBtn.position();
        left = offset.left;
        top = offset.top + params.toggleBtn.outerHeight();

        if (isElementVerticallyOverflowingParent(params.popupMenu, docOffset.top) && !params.bottomFixed) {
            menuClasses += ' -arrow-inverted';
            handleOverflowForVerticallyInvertedPopup(params.popupMenu, docOffset.top);
            top -= (params.toggleBtn.outerHeight() + params.popupMenu.outerHeight() + MENU_VERTICAL_OFFSET_INVERTED);
        }

        if (params.scrollingContainerSelector) {
            top += $(params.scrollingContainerSelector).scrollTop()
        }
    }

    if (shouldFlipPopupToTheLeft(params.toggleBtn, params.popupMenu, parent)) {
        left -= params.popupMenu.width() - params.toggleBtn.outerWidth();
        menuClasses += ' -arrow-left';
    }

    left = params.leftFixed ? $(params.scrollingContainerSelector).css('padding-left') : left + 'px';

    params.popupMenu.css({
        left: left,
        top: (top + params.menuverticalOffset) + 'px',
        visibility: 'visible'
    });

    if (menuClasses.length > 0) {
        params.popupMenu.addClass(menuClasses);
    }

}


/**
 *
 * @param {jQuery} popupElement
 * @param {number} elementDocumentTopOffset
 */
const handleOverflowForVerticallyInvertedPopup = (popupElement, elementDocumentTopOffset) => {
    if ((elementDocumentTopOffset - MENU_VERTICAL_OFFSET_INVERTED - popupElement.outerHeight()) < 0) {
        popupElement.css({
            overflow: 'auto',
            maxHeight: `${elementDocumentTopOffset * (CAPPED_MENU_MAX_HEIGHT_PERCENTAGE / 100)}px`
        });
    }
}

/**
 *
 * @param {jQuery} element
 * @param {number} elementDocOffset
 * @return {boolean}
 */
const isElementVerticallyOverflowingParent = (element, elementDocOffset) => {
    let nonOverflowingParent = getNonOverflowingParent(element);
    return nonOverflowingParent.length > 0 && (element.height() + elementDocOffset > nonOverflowingParent.height() + nonOverflowingParent.offset().top);
}

/**
 * gets the ancestor node that prevents overflow by either having overflow: hidden or having the INVERT_POPUP_ON_OVERFLOW_CLASS
 * @param {jQuery} element
 * @return {jQuery}
 */
const getNonOverflowingParent = (element) => {
    return element.parents().filter((index, parent) => {
        const parentJq = $(parent);
        return parentJq.css('overflow') === 'hidden' || parentJq.hasClass(INVERT_POPUP_ON_OVERFLOW_CLASS);
    }).first()
}

/**
 *
 * @param {Event} e
 * @return {boolean}
 */
export const isClickFromPopup = (e) => {
    return !!(e.target.closest('.js-popup-menu-toggle-btn') || e.target.closest('.js-popup-menu'));
}

/**
 * calculates and sets the appropriate positioning for the popup menu
 * @param {jQuery} toggleButton
 * @param {jQuery} popupMenu
 * @param {jQuery} parent
 */
const isPopupHorizontallyOverflowingParent = (toggleButton, popupMenu, parent) => {
    const toggleButtonOffsetFromDoc = toggleButton.offset();
    const parentEndingPositionInDoc = parent.offset().left + parent.outerWidth();
    return popupMenu.width() + toggleButtonOffsetFromDoc.left > parentEndingPositionInDoc;
}

/**
 *
 * @param {jQuery} toggleButton
 * @param {jQuery} popupMenu
 * @return {boolean}
 */
const isPopupHorizontallyOverflowingTheDocument = (toggleButton, popupMenu) => {
    const toggleButtonOffsetFromDoc = toggleButton.offset();
    return popupMenu.width() + toggleButtonOffsetFromDoc.left > (document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)
}

/**
 *
 * @param {jQuery} toggleButton
 * @param {jQuery} popupMenu
 * @param {jQuery} parent
 * @return {boolean}
 */
const shouldFlipPopupToTheLeft = (toggleButton, popupMenu, parent) => {
    const parentWithOverflowHidden = getNonOverflowingParent(popupMenu);
    return (parentWithOverflowHidden.length > 0 && isPopupHorizontallyOverflowingParent(toggleButton, popupMenu, parentWithOverflowHidden)) || isPopupHorizontallyOverflowingTheDocument(toggleButton, popupMenu);
}


PopupMenu.propTypes = {
    /**
     * The button that opens/closes the popup. The button will receive an '-open' class when toggled.
     * The button must support an onClick prop
     * By default, an icon button with three dots is shown if this prop is not passed
     */
    menuToggleButton: PropTypes.object,
    /**
     * any classes to give to the root level element
     */
    classes: PropTypes.string,

    /**
     * the classes to give to the menu list. these classes will also get applied to the modal
     * if the menu opens as a modal
     */
    menuClasses: PropTypes.string,
    /**
     * optional max-width (in pixels) property to apply to the menu's bounding box
     */
    maxWidth: PropTypes.number,
    /**
     * show an arrow on top of the menu or not that points towards the toggle button
     */
    withArrow: PropTypes.bool,

    openModalOnPhone: PropTypes.bool,

    modalTitle: PropTypes.string,

    /**
     * fix the popup menu position on the left of container element
     */
    leftFixed: PropTypes.bool,

    /**
     * fix the popup menu position on the bottom of toggle element
     */
    bottomFixed: PropTypes.bool,

    scrollingContainerSelector: PropTypes.string,

    /**
     * If false, doesn't close the popup on inside clicks
     */
    doCloseOnClick: PropTypes.bool,

    popupHasSpacing: PropTypes.bool

}

PopupMenu.defaultProps = {
    menuToggleButton: <PopupToggleButton/>,
    classes: '',
    menuClasses: '',
    menuverticalOffset: MENU_DEFAULT_VERTICAL_OFFSET,
    withArrow: false,
    openModalOnPhone: false,
    modalTitle: '',
    leftFixed: false,
    bottomFixed: false,
    doCloseOnClick: true
}

export default PopupMenu;