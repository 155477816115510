import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {FancyTextEffectHashmap} from '@Libraries/fancy-text-library';
import {MorphType} from '@Libraries/fancy-text-library';
import type {RGBA} from '@Utils/color.util';
import type {DeepPartial} from '@/global';

interface State {
  selection: FancyTextSelection;
  properties: FancyTextProperties;
  data: FancyTextData;
  panelStates: PanelStates;
}

interface PanelStates {
  selectedColorIndex: number;
  panelViewState: AddFancyTextPanelsType;
}

export enum AddFancyTextPanelsType {
  DEFAULT = 'DEFAULT',
  COLOR = 'COLOR',
}

interface FancyTextData {
  fancyTextEffectHashmap: FancyTextEffectHashmap;
}

interface FancyTextSelection {
  selectedEffectId: string;
  selectedFontFamily: string;
}

interface FancyTextProperties {
  text: string;
  arePropertiesChanging?: boolean;
  colors: RGBA[];
  morphType: MorphType;
  morphAmount: number;
}

export const DEFAULT_SELECTED_EFFECT = '31';
export const DEFAULT_SELECTED_FONT_FAMILY = 'Big Apple NF';
export const DEFAULT_FANCY_TEXT_COLORS: RGBA[] = [
  [217, 43, 68, 1],
  [0, 73, 92, 1],
  [249, 254, 247, 1],
];

const initialState: State = {
  selection: {
    selectedEffectId: DEFAULT_SELECTED_EFFECT,
    selectedFontFamily: DEFAULT_SELECTED_FONT_FAMILY,
  },
  data: {
    fancyTextEffectHashmap: {},
  },
  properties: {
    text: 'add your text',
    arePropertiesChanging: false,
    colors: [
      [217, 43, 68, 1],
      [0, 73, 92, 1],
      [249, 254, 247, 1],
    ],
    morphType: MorphType.NONE,
    morphAmount: 60,
  },
  panelStates: {
    panelViewState: AddFancyTextPanelsType.DEFAULT,
    selectedColorIndex: 0,
  },
};

interface SetFancyTextSelectedStateActionProps {
  effectId: string;
  fontFamily: string;
}

export const FancyTextSlice = createSlice({
  name: 'fancyText',
  initialState,
  reducers: {
    setFancyTextEffectHashamp: (state, action: PayloadAction<FancyTextEffectHashmap>) => {
      state.data.fancyTextEffectHashmap = action.payload;
    },
    setFancyTextSelectedState: (state, action: PayloadAction<SetFancyTextSelectedStateActionProps>) => {
      state.selection = {
        selectedEffectId: action.payload.effectId,
        selectedFontFamily: action.payload.fontFamily,
      };
    },
    setFancyTextSelectedStateFromId: (state, action: PayloadAction<string>) => {
      if (state.data.fancyTextEffectHashmap[action.payload]) {
        state.selection = {
          selectedEffectId: action.payload,
          selectedFontFamily: state.data.fancyTextEffectHashmap[action.payload].defaultFamilyName,
        };
      }
    },
    setSelectedFont: (state, action: PayloadAction<string>) => {
      state.selection.selectedFontFamily = action.payload;
    },
    setSelectedFontFromId: (state, action: PayloadAction<string>) => {
      if (state.data.fancyTextEffectHashmap[action.payload]) {
        state.selection.selectedFontFamily = state.data.fancyTextEffectHashmap[action.payload].defaultFamilyName;
      }
    },
    setSelectedFancytextEffect: (state, action: PayloadAction<string>) => {
      state.selection.selectedEffectId = action.payload;
    },
    togglePropertiesChaningState: (state) => {
      state.properties.arePropertiesChanging = !state.properties.arePropertiesChanging;
    },
    updateFancyTextProperties: (state, action: PayloadAction<DeepPartial<FancyTextProperties>>) => {
      state.properties = {
        ...state.properties,
        ...action.payload,
      };
    },
    toggleFancyTextPanelViewState: (state, action: PayloadAction<AddFancyTextPanelsType>) => {
      state.panelStates.panelViewState = action.payload;
    },
    updateSelectedColorIndexForFancyText: (state, action: PayloadAction<number>) => {
      state.panelStates.selectedColorIndex = action.payload;
    },
  },
});

export const {
  setFancyTextEffectHashamp,
  setFancyTextSelectedStateFromId,
  setFancyTextSelectedState,
  setSelectedFont,
  setSelectedFancytextEffect,
  togglePropertiesChaningState,
  updateFancyTextProperties,
  setSelectedFontFromId,
  toggleFancyTextPanelViewState,
  updateSelectedColorIndexForFancyText,
} = FancyTextSlice.actions;
export const fancyTextReducer = FancyTextSlice.reducer;
