import {noop} from '@Utils/general.util';
import {loadFontFamilyInIframe} from '@Libraries/events.library';
import type {SocialMediaProfileLinksMap} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {convertWebpURLToJPG} from "@Utils/url.util";

export class EventsHomeIframePreviewHelper {
  iframeClass = '';

  constructor(iframeClass: string) {
    this.iframeClass = iframeClass;
  }

  setEventsHomeTitle = (title: string): void => {
    this.#setTextInIFrame('.js-organizer-title', title);
  };

  setEventsHomeDescription = (description: string): void => {
    this.#showItem('.js-organizer-description');
    this.#setTextInIFrame('.js-organizer-description', description);
  };

  applyFontFamilyToHeading = (fontFamily: string): void => {
    const iframeDocument = this.#getIFrameDocument();

    if (iframeDocument) {
      loadFontFamilyInIframe(iframeDocument, fontFamily);
    }
  };

  applyColorInIframe = (colorHex: string, secondaryColor: string): void => {
    const contactRow = this.#getIFrameDocument()?.querySelector('.js-organizer-contacts-row');
    const contactTexts: NodeListOf<HTMLSpanElement> | undefined | null = contactRow?.querySelectorAll('.js-contact-text');
    const contactIcons: NodeListOf<HTMLSpanElement> | undefined | null = contactRow?.querySelectorAll('.js-icon');
    const btns: NodeListOf<HTMLButtonElement> | undefined | null = this.#getIFrameDocument()?.querySelectorAll('.js-event-schedule-action:not(.-disabled)');

    if (btns) {
      btns.forEach((btn) => {
        btn.style.background = colorHex;
      });
    }

    if (contactTexts) {
      contactTexts.forEach((text) => {
        text.style.color = secondaryColor;
      });
    }

    if (contactIcons) {
      contactIcons.forEach((icon) => {
        icon.style.color = secondaryColor;
      });
    }
  };

  setImagesInIframe = (profilePictureURL?: string, coverPhotoURL?: string): void => {
    if (profilePictureURL) {
      this.setProfilePictureImagesInIframe(profilePictureURL);
    }

    if (coverPhotoURL) {
      this.setCoverPhotoInIframe(coverPhotoURL, convertWebpURLToJPG(coverPhotoURL));
    } else {
      this.removeCoverPhotoInIFrame();
    }
  };

  setProfilePictureImagesInIframe = (newImageURL: string, onImageLoaded: VoidFunction = noop): void => {
    const profilePicture: HTMLImageElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-profile-picture');
    const profilePictureBackground: HTMLImageElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-profile-picture-bg');

    if (profilePicture) {
      profilePicture.src = newImageURL;
      profilePicture.addEventListener(
        'load',
        () => {
          this.removeIframeProfilePictureLoading();
          onImageLoaded();
        },
        {once: true}
      );
    }

    if (profilePictureBackground) {
      profilePictureBackground.src = newImageURL;
    }

    if (!this.#isCoverPhotoSet()) {
      this.#setImageBackground(newImageURL);
    }

    this.#removeEmptyStateClassFromPictureContainer();
    this.#getImagesSectionElement()?.classList?.remove('-no-profile-pic');
  };

  setCoverPhotoInIframe = (coverPhotoURLWebp: string, coverPhotoURLJpg: string, onImageLoaded: VoidFunction = noop, onImageLoadError: VoidFunction = noop): void => {
    const coverPhoto: HTMLSourceElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-cover-photo-img-webp');
    const coverPhotoImgTag: HTMLImageElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-cover-photo-img-jpg');

    if (coverPhoto && coverPhotoImgTag) {
      coverPhoto.srcset = coverPhotoURLWebp;
      coverPhotoImgTag.src = coverPhotoURLJpg;
      coverPhotoImgTag.addEventListener('load', onImageLoaded, {once: true});
      coverPhotoImgTag.addEventListener('error', onImageLoadError, {once: true});
    }

    this.#setImageBackground(coverPhotoURLJpg);
    this.#getImagesSectionElement()?.classList?.add('-has-cover-photo');
    this.#removeEmptyStateClassFromPictureContainer();
  };

  removeCoverPhotoInIFrame = (): void => {
    const profilePictureSrc = this.#getProfilePictureImg()?.getAttribute('src');

    if (profilePictureSrc) {
      this.#setImageBackground(profilePictureSrc);
    } else {
      this.#addEmptyStateClassToPictureContainer();
    }
    this.#getImagesSectionElement()?.classList?.remove('-has-cover-photo');
  };

  setIframeProfilePictureLoading = (): void => {
    const profilePictureContainer: HTMLDivElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-profile-picture-container');

    if (profilePictureContainer) {
      profilePictureContainer.classList.add('-loading');
    }
  };

  removeIframeProfilePictureLoading = (): void => {
    const profilePictureContainer: HTMLDivElement | null | undefined = this.#getIframeElement()?.contentWindow?.document?.querySelector('.js-profile-picture-container');

    if (profilePictureContainer) {
      profilePictureContainer.classList.remove('-loading');
    }
  };

  togglePastEvents = (showPastEvents: boolean): void => {
    if (!showPastEvents) {
      this.hidePastEvents();
    } else {
      this.showPastEvents();
    }
  };

  hidePastEvents = (): void => {
    this.#hideItem('.js-past-events');
  };

  showPastEvents = (): void => {
    if (this.#getIFrameDocument()?.querySelector('.js-past-events .js-event-list-item')) {
      this.#showItem('.js-past-events');
    }
  };

  toggleWatermark = (doRemoveWatermark: boolean): void => {
    if (doRemoveWatermark) {
      this.removeWaterMark();
    } else {
      this.showWaterMark();
    }
  };

  showWaterMark = (): void => {
    this.#showItem('.js-events-footer');
  };

  removeWaterMark = (): void => {
    this.#hideItem('.js-events-footer');
  };

  setEmailInIframe(email: string): void {
    this.#setTextInIFrame('.js-email-row .js-contact-text', email);
    this.#toggleContactsRowVisibility();
  }

  setPhoneNumberInIframe(phoneNumber: string): void {
    this.#setTextInIFrame('.js-phone-number-row .js-contact-text', phoneNumber);
    this.#toggleContactsRowVisibility();
  }

  setAllSocialLinksInIframe = (profilesMap: SocialMediaProfileLinksMap): void => {
    Object.keys(profilesMap).forEach((socialMediaProfile: string): void => {
      this.setSocialLinkInIframe(socialMediaProfile as SocialMediaProfile, profilesMap[socialMediaProfile as SocialMediaProfile]);
    });
  };

  setSocialLinkInIframe(socialMediaProfile: SocialMediaProfile, url: string): void {
    const selectorToUse = this.#getSelectorForSocialProfileImage(socialMediaProfile);

    if (!url) {
      this.#setDefaultHrefForProfileLinkSelector(selectorToUse);
      this.#hideItem(selectorToUse);
      return;
    }

    this.#setSocialProfileLinkForSelector(selectorToUse, url);
    this.#showItem(selectorToUse);
  }

  #setTextInIFrame = (textNodeSelector: string, text: string): void => {
    const textNode = this.#getIframeElement()?.contentWindow?.document?.querySelector(textNodeSelector);
    if (textNode) {
      textNode.textContent = text;
    }
  };

  #getIframeElement = (): HTMLIFrameElement | null => {
    return document.querySelector(`.${this.iframeClass}`);
  };

  #getIFrameDocument = (): Document | null | undefined => {
    return this.#getIframeElement()?.contentWindow?.document;
  };

  #getImagesSectionElement = (): HTMLElement | undefined | null => {
    return this.#getIFrameDocument()?.querySelector('.js-page-images-section');
  };

  #getBgImageElement = (): HTMLImageElement | undefined | null => {
    return this.#getImagesSectionElement()?.querySelector('.js-bg-img');
  };

  #isCoverPhotoSet = (): boolean => {
    return this.#getImagesSectionElement()?.classList?.contains('-has-cover-photo') ?? false;
  };

  #removeEmptyStateClassFromPictureContainer = (): void => {
    this.#getImagesSectionElement()?.classList?.remove('-empty');
  };

  #addEmptyStateClassToPictureContainer = (): void => {
    this.#getImagesSectionElement()?.classList?.add('-empty');
  };

  #getProfilePictureImg = (): HTMLImageElement | undefined | null => {
    return this.#getIFrameDocument()?.querySelector('.js-profile-picture');
  };

  #setImageBackground = (imageUrl: string): void => {
    const backgroundImage = this.#getBgImageElement();
    if (backgroundImage) {
      backgroundImage.src = imageUrl;
    }
  };

  #showContactsRow = (): void => {
    this.#showItem('.js-organizer-contacts-row');
  };

  #hideContactsRow = (): void => {
    this.#hideItem('.js-organizer-contacts-row');
  };

  #toggleContactsRowVisibility = (): void => {
    if (this.#shouldHideContactRow()) {
      this.#hideContactsRow();
      return;
    }

    this.#showContactsRow();
  };

  #shouldHideContactRow = (): boolean => {
    const contactRow = this.#getIFrameDocument()?.querySelector('.js-organizer-contacts-row');
    if (!contactRow) {
      return false;
    }

    const contactTexts: NodeListOf<HTMLElement> = contactRow.querySelectorAll('.js-contact-text');
    if (!contactRow) {
      return false;
    }

    for (let i = 0; i < contactTexts.length; i++) {
      if (contactTexts[i].textContent !== '') {
        return false;
      }
    }

    return true;
  };

  #setSocialProfileLinkForSelector = (selector: string, url: string): void => {
    this.#getIFrameDocument()?.querySelector(`.js-social-img-container${selector}`)?.setAttribute('href', url);
  };

  #setDefaultHrefForProfileLinkSelector = (selector: string): void => {
    this.#getIFrameDocument()?.querySelector(`.js-social-img-container${selector}`)?.setAttribute('href', '#');
  };

  #getSelectorForSocialProfileImage = (socialMediaProfile: SocialMediaProfile): string => {
    switch (socialMediaProfile) {
      case SocialMediaProfile.INSTAGRAM:
        return '.js-insta';

      case SocialMediaProfile.FACEBOOK:
        return '.js-fb';

      case SocialMediaProfile.X:
        return '.js-x';

      case SocialMediaProfile.TIKTOK:
        return '.js-tiktok';

      case SocialMediaProfile.LINKEDIN:
        return '.js-pinterest';

      case SocialMediaProfile.PINTEREST:
        return '.js-linkedin';

      case SocialMediaProfile.YOUTUBE:
        return '.js-youtube';

      default:
        return '';
    }
  };

  #hideItem = (selector: string): void => {
    this.#getIFrameDocument()?.querySelector(selector)?.classList.add('hidden');
  };

  #showItem = (selector: string): void => {
    this.#getIFrameDocument()?.querySelector(selector)?.classList.remove('hidden');
  };
}
