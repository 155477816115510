import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {isUserPremiumPlus} from '@Libraries/user.library';

export const getMaxRecurringDate = (startDate: Date, recurringType: RecurringType): Date => {
  const oneDayAheadMs = 24 * 60 * 60 * 1000;
  const oneYearAheadMs: number = 365 * oneDayAheadMs;
  const oneMonthAheadMs: number = 31 * oneDayAheadMs;

  if (recurringType === RecurringType.DAILY) {
    return new Date(startDate.getTime() + oneMonthAheadMs);
  }
  return new Date(startDate.getTime() + oneYearAheadMs);
};

export const shouldUpsellUserForRecurring = (): boolean => {
  return !isUserPremiumPlus();
};
