import type {OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';

export const getOrganiserProfilePicture = (organiserDetails: OrganiserDetails): string => {
  if (!organiserDetails.profilePic || !organiserDetails.profilePic.trim()) {
    return getDefaultProfilePicture();
  }
  return organiserDetails.profilePic;
};

export const getDefaultProfilePicture = (): string => {
  return window.PMW.util.asset_url('images/default_profile_picture_small.png');
};
