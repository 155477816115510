import type {KeyboardEvent} from 'react';

export type InputType = number | string;
export const DEFAULT_NUMBER_OF_ROWS = 5;

export interface InputFieldHandlers {
  onInputChange?(value: InputType): void;
  onInputChangeEnded?(value: InputType): void;
  onInputBlur?(value: InputType): void;
  onInputFocus?(value: InputType): void;
  onInputSubmit?(): void;
  onArrowUpKeyDown?(e?: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onArrowDownKeyDown?(): void;
  onBackSpaceKeyDown?(e?: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

export interface InputFieldControls {
  showDefaultControls?: boolean;
  showSingleDefaultControlOnly?: boolean;
  customControls?: InputFieldControlItemData[];

  onClick?(iconName: string, e?: InputFieldControlItemEvent): void;
  doBlurInputOnClick?: boolean;
}

export type InputFieldControlItemEvent = React.KeyboardEvent | React.MouseEvent;

export type InputFieldControlItemData = IconsOnlyControl | IconBtnControl | InActiveIconControl | InActiveTextControl;

export interface InputFieldBaseControlItem {
  id: string;
  type: InputControlItemType;
  className?: string;
  isLoading?: boolean;
  containerClassName?: string;
}

export interface IconsOnlyControl extends InputFieldBaseControlItem, BaseIconControl {
  type: InputControlItemType.ICONS_ONLY;
}

export interface IconBtnControl extends InputFieldBaseControlItem, BaseIconControl {
  type: InputControlItemType.ICON_BTNS;
  isBorderLess?: boolean;
}

export interface InActiveIconControl extends InputFieldBaseControlItem, BaseIconControl {
  type: InputControlItemType.INACTIVE_ICON;
}

export interface InActiveTextControl extends InputFieldBaseControlItem {
  type: InputControlItemType.INACTIVE_TEXT;
  text: string;
}

export interface InputFieldNumberProperties {
  min?: number;
  max?: number;
  step?: number;
}

export interface BaseIconControl {
  icon: string;
}

export enum InputControlItemType {
  ICONS_ONLY = 'ICONS_ONLY',
  ICON_BTNS = 'ICON_BTNS',
  INACTIVE_ICON = 'INACTIVE_ICON',
  INACTIVE_TEXT = 'INACTIVE_TEXT',
}

export enum InputFieldSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum InputFieldType {
  TEXT = 'text',
  HIDDEN = 'hidden',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  SEARCH = 'search',
  DATE = 'date',
}

export enum InputFieldState {
  ERROR = 'ERROR',
  DEFAULT = 'DEFAULT',
  SUCCESS = 'SUCCESS',
}
