import type {Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';

export interface MonthDaySelectorsProps {}

export interface RecurringMonth {
  weekdayNum: string;
  weekday: Weekdays;
}

export const WEEKDAY_NUMS = ['1st', '2nd', '3rd', '4th', 'last'];

export const getWeekdayOrdinalFromDate = (date: Date): string => {
  const dayOfMonth = date.getDate();
  const dayOfWeek = date.getDay();

  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstWeekdayOfMonth = firstDayOfMonth.getDay();

  const firstOccurrence = 1 + ((dayOfWeek - firstWeekdayOfMonth + 7) % 7);
  const numWeekday = Math.floor((dayOfMonth - firstOccurrence) / 7) + 1;

  return WEEKDAY_NUMS[numWeekday - 1];
};
