import type {ReactElement} from 'react';
import React, {useState} from 'react';
import {PopupMenuItem, PopupToggleButton} from '@Components/popup-menu/components';
import {PopupMenuToggleItem} from '@Components/popup-menu/components/popup-menu-toggle-item/popup-menu-toggle-item';
import {PopupMenuItemIconSize, PopupMenuItemTypeInModal} from '@Components/popup-menu/components/popup-menu-item/popup-menu-item';
import type {GridEmailSignupFormItemStorage} from '@Components/email-signup-form-grid/components/email-signup-form-grid';
import type {GridItemBaseStorage, GridItemStorage} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE} from '@Components/base-grid/components/grid-item';
import {executeThunk} from '@Utils/thunk.util';
import {deleteEmailSignupForm, duplicateEmailSignupForm, updateEmailSignupFormNotificationsPreference} from '@Components/email-signup-form-grid/email-signup-form-grid-thunk';
import {openPublishEmailSignupFormDialog, pollForEmailSignupFormPreview} from '@Libraries/email-signup-form-grid-library';
import {addItemsToGrid} from '@Components/base-grid';
import {SIGNUP_GRID_ID} from '@Libraries/email-signup-form-library';
import PopupMenu from '../../../../../popup-menu/popup-menu';
import {useAppDispatch} from '@/hooks';

interface SignupFormGridItemOptionsProps {
  gridItem: GridEmailSignupFormItemStorage;
}

export function SignupFormGridItemOptions({gridItem}: SignupFormGridItemOptionsProps): ReactElement {
  const dispatch = useAppDispatch();
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(gridItem.areNotificationsEnabled);

  const onToggleChange = (): void => {
    const newToggleValue = !isToggleChecked;
    void onUpdateNotificationsPreference(newToggleValue);
  };

  const onUpdateNotificationsPreference = async (isNotificationsToggleChecked: boolean): Promise<void> => {
    setIsToggleLoading(true);
    await executeThunk(
      () => {
        return dispatch(
          updateEmailSignupFormNotificationsPreference({
            idEmailSignupForm: gridItem.id,
            areNotificationsEnabled: isNotificationsToggleChecked,
          })
        );
      },
      (): void => {
        setIsToggleChecked(isNotificationsToggleChecked);
        setIsToggleLoading(false);
      },
      (): void => {
        setIsToggleLoading(false);
      }
    );
  };

  const getEditURL = (): string => {
    return window.PMW.util.site_url(`formmaker/load/${gridItem.id}`);
  };

  const onPublish = (): void => {
    openPublishEmailSignupFormDialog(gridItem.id);
  };

  const getDataForDuplicateGridItem = (payload: GridItemStorage): GridItemBaseStorage => {
    return {
      type: GRID_ITEM_TYPE.EMAIL_SIGNUP_FORM,
      id: payload.id,
      name: gridItem.name,
    };
  };

  const onDuplicate = async (): Promise<void> => {
    await executeThunk(
      () => {
        return dispatch(
          duplicateEmailSignupForm({
            idEmailSignupForm: gridItem.id,
          })
        );
      },
      (payload: GridItemStorage): void => {
        pollForEmailSignupFormPreview(payload.id);
        dispatch(
          addItemsToGrid({
            id: SIGNUP_GRID_ID,
            items: [getDataForDuplicateGridItem(payload)],
            index: 1,
          })
        );
      }
    );
  };

  const onDeleteEmailSignupFormConfirmation = async (): Promise<void> => {
    await executeThunk(() => {
      return dispatch(
        deleteEmailSignupForm({
          idEmailSignupForm: gridItem.id,
        })
      );
    });
  };

  const onDelete = (): void => {
    window.PMW.openConfirmDeleteModal({
      title: window.i18next.t('pmwjs_signup_form_delete'),
      text: window.i18next.t('pmwjs_signup_form_delete_message'),
      ctaText: window.i18next.t('pmwjs_delete'),
      onDeleteConfirmation: onDeleteEmailSignupFormConfirmation,
    });
  };

  return (
    <PopupMenu
      openModalOnPhone
      modalTitle={window.i18next.t('pmwjs_more_options')}
      menuToggleButton={<PopupToggleButton className="flexbox spacing-m-l-3 -neutral -smaller-icon" />}
    >
      <PopupMenuItem
        itemClasses="-smaller radius-4 -more-margin-horizontal"
        text={window.i18next.t('pmwjs_edit_item')}
        iconClasses="icon icon-pencil"
        href={getEditURL()}
        textColorClasses="content-body"
        textClasses="spacing-m-l-2"
        iconSize={PopupMenuItemIconSize.SMALL}
        horizontalAlignStart
        popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
      />
      <PopupMenuItem
        itemClasses="-smaller radius-4 -more-margin-horizontal"
        text={window.i18next.t('pmwjs_publish')}
        iconClasses="icon icon-publish-arrow"
        clickHandler={onPublish}
        textColorClasses="content-body"
        textClasses="spacing-m-l-2"
        iconSize={PopupMenuItemIconSize.SMALL}
        horizontalAlignStart
        popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
      />
      <PopupMenuItem
        itemClasses="-smaller radius-4 -more-margin-horizontal"
        text={window.i18next.t('pmwjs_duplicate')}
        iconClasses="icon icon-duplicate"
        clickHandler={onDuplicate}
        textColorClasses="content-body"
        textClasses="spacing-m-l-2"
        iconSize={PopupMenuItemIconSize.SMALL}
        horizontalAlignStart
        popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
      />
      <PopupMenuItem
        itemClasses="-smaller radius-4 -more-margin-horizontal -danger"
        text={window.i18next.t('pmwjs_delete')}
        iconClasses="icon icon-delete color-danger-100"
        clickHandler={onDelete}
        textColorClasses="content-body"
        textClasses="spacing-m-l-2"
        iconSize={PopupMenuItemIconSize.SMALL}
        horizontalAlignStart
        popupMenuTypeInModal={PopupMenuItemTypeInModal.UNBORDERED_HORIZONTALLY_ALIGNED_START}
      />
      <PopupMenuToggleItem
        text={window.i18next.t('pmwjs_subscriber_notifications')}
        isChecked={isToggleChecked}
        isLoading={isToggleLoading}
        onChange={onToggleChange}
        className="spacing-m-t-4 spacing-m-b-4"
      />
    </PopupMenu>
  );
}
