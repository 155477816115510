import type {LoadingStates} from '@Utils/loading.util';

export interface DesignStyle {
  key: string;
  name: string;
  description: string;
  posterPreviewUrls: string[];
  keywords?: string[];
}

export interface TemplateInfo {
  hashedId: string;
  previewUrl: string;
  displayName: string;
}

export interface TemplatesInfoAjaxResponse {
  templatesInfo: TemplateInfo[];
}

export enum ImproveTemplatesSortType {
  SALES = 'sales',
  TIME_CREATED = 'time_created',
}

export interface ImproveTemplatesInfo {
  isImproveTemplatesFlow: boolean;
  improveTemplatesLoadingState: LoadingStates;
  numTemplatesWithDesignStyles: number;
  numTotalTemplates: number;
  showSuccessBanner: boolean;
  templatesToProcess: TemplateInfo[];
  improveTemplatesSortType: ImproveTemplatesSortType;
  doLoadMoreTemplates: boolean;
  offset: number;
  processedTemplates: TemplateInfo[];
  isPosterViewFlow: boolean;
}

export interface TemplateSubmissionFlowInfo extends ImproveTemplatesInfo {
  allDesignStylesInfo: DesignStyle[];
  selectedPosterHashedId: string;
  selectedPosterPreviewUrl: string;
  posterColor: string;
  posterType: string;
  designerProfileUri: string;
  templateName: string;
  isPreviewOutdated: boolean;
}

export interface TemplateSubmissionFlowReducerState extends TemplateSubmissionFlowInfo {
  selectedDesignStyles: Record<string, boolean>;
  currentStep: SubmissionFlowStep;
  nextStep: SubmissionFlowStep;
  templateName: string;
  templateTags: string[];
  isTermsAndConditionsSelected: boolean;
  isAcknowledgementSelected: boolean;
  isSubmitting: boolean;
  templateNameInput: string;
  selectedTemplateNameSuggestionPill: string;
}

export enum SubmissionFlowStep {
  DESIGN_STYLE = 1,
  NAME = 2,
  TAGS = 3,
  IN_REVIEW = 4,
  LAST = 5,
}

export interface TemplateSubmissionFlowContainerQuery {
  isMobileScreen?: boolean;
  isTabletScreen?: boolean;
  isLaptopScreen?: boolean;
  isDesktopScreen?: boolean;
}

export interface TemplateSubmissionData {
  selectedDesignStyles: string[];
  templateName: string;
  templateTags: string[];
  isTermsAndConditionsSelected: boolean;
  isAcknowledgementSelected: boolean;
  selectedPosterHashedId: string;
}

export interface ProcessTemplatesData {
  sortType?: ImproveTemplatesSortType;
  offset?: number;
}

export interface TemplateDesignStylesData extends ProcessTemplatesData {
  selectedDesignStyles: string[];
  selectedPosterHashedId: string;
}
