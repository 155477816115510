import type {ReactElement} from 'react';
import React from 'react';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '@Components/circular-progress-loader';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import styles from './toggle-switch.module.scss';
import {isMobile} from 'react-device-detect';

interface ToggleSwitchV2Props {
  id?: string;
  className?: string;
  isChecked: boolean;
  isDisabled?: boolean;

  isLoading?: boolean;
  icon?: string;
  elementTitle?: string;

  onChange(): void;

  onMouseEnter?(): void;

  onMouseLeave?(): void;

  size?: ToggleSwitchV2Size;
  iconClassName?: string;
}

export enum ToggleSwitchV2Size {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export function ToggleSwitch({
  id = '',
  className = '',
  isLoading = false,
  icon = '',
  isDisabled = false,
  onMouseEnter = (): void => {},
  onMouseLeave = (): void => {},
  size = ToggleSwitchV2Size.MEDIUM,
  iconClassName = '',
  ...props
}: ToggleSwitchV2Props): ReactElement {
  const isChecked = isDisabled ? false : props.isChecked;

  const getClassForSize = (): string => {
    switch (size) {
      case ToggleSwitchV2Size.SMALL:
        return styles.small;
      case ToggleSwitchV2Size.MEDIUM:
        return styles.medium;
      default:
        return '';
    }
  };

  const showContent = (): ReactElement | null => {
    if (icon || isLoading) {
      return <div className={`${styles.iconContainer} flex-center`}>{showContentItems()}</div>;
    }

    return null;
  };

  const getLoaderColor = (): LOADER_COLOR => {
    if (isChecked) {
      return LOADER_COLOR.PRIMARY_DARK;
    }
    return LOADER_COLOR.DARK;
  };

  const showContentItems = (): ReactElement | null => {
    if (isLoading) {
      return <CircularProgressLoader color={getLoaderColor()} size={size === ToggleSwitchV2Size.MEDIUM ? LOADER_SIZE.XSMALL : LOADER_SIZE.XXSMALL} classes="spacing-m-0" />;
    }

    if (icon) {
      return <Icon icon={icon} size={IconSize.SIZE_ICON_16} className={`${isChecked ? 'content-primary' : styles.icon} ${iconClassName} `} />;
    }

    return null;
  };

  const onMouseEnterHandler = (): void => {
    onMouseEnter();
  };
  const onMouseLeaveHandler = (): void => {
    onMouseLeave();
  };

  return (
    <label onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} id={id} className={className} aria-label={id}>
      <span className={`flexbox ${styles.toggleSwitch} ${isChecked ? styles.checkedToggleSwitch : styles.unCheckedToggleSwitch} ${getClassForSize()} hover-transition-all`}>
        <input
          type="checkbox"
          checked={props.isChecked}
          disabled={isDisabled}
          aria-labelledby={id}
          onChange={(): void => {
            if (isLoading) {
              return;
            }
            props.onChange();
          }}
        />
        <span className={`${isChecked ? styles.checked : ''} ${styles.switch} ${size === ToggleSwitchV2Size.SMALL && isMobile ? styles.offsetTop : ''}`}>
          <span className={`${styles.switchHandle}  radius-round ${isDisabled ? styles.disabledHandle : ''}  ${isChecked || isDisabled ? '' : `${styles.unCheckedHandle} `} `}>
            {showContent()}
          </span>
        </span>
      </span>
    </label>
  );
}
