import type {GridMediaItemStorage} from '@Components/base-grid/components/grid-media-item';
import {GridMediaItem} from '@Components/base-grid/components/grid-media-item';
import type {GridItemProps} from '@Components/base-grid/components/grid-item';
import type {ReactElement} from 'react';
import React, {useState} from 'react';
import styles from '@Components/base-grid/components/grid-animated-sticker-item/grid-animated-sticker-item.module.scss';

export interface GridExtractedGettyStickerItemStorage extends GridMediaItemStorage {
  hashedFilename: string;
  width?: number;
  height?: number;
}

interface GridExtractedGettyStickerItemProps extends GridItemProps {
  thumbSrc: string;
}

export function GridExtractedGettyStickerItem({...props}: GridExtractedGettyStickerItemProps): ReactElement {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <GridMediaItem
      {...props}
      isImageLoaded={isImageLoaded}
      thumb={
        <img
          onLoad={(): void => {
            setIsImageLoaded(true);
          }}
          src={props.thumbSrc}
          className={styles.thumb}
          alt={window.i18next.t('pmwjs_sticker')}
          draggable="false"
          loading="lazy"
        />
      }
    />
  );
}
