import type {CopyableItemStylesAndProperties} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import type {DeepPartial} from '@/global';
import type {ItemObject, ItemType} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGBA} from '@Utils/color.util';
import type {FancyTextItem, FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {StickerItem, StickerItemObject} from '@PosterWhiteboard/items/sticker-item.class';
import {EdgeType} from '@PosterWhiteboard/classes/item-effects.class';
import type {ImageItem, ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {LayoutBackgroundTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {QRCodeItem, QRCodeItemObject} from '@PosterWhiteboard/items/qr-code-item.class';
import type {VideoItem, VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import {TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {SlideshowItem, SlideshowItemObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {TextSlideItem, TextSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {ImageSlideItem, ImageSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem, VideoSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {TableItem, TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {MenuItem, MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TabsItem, TabsItemObject} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {ImageBackgroundItem} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {RectangleItem, RectangleItemObject} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {LineItem, LineItemObject} from '@PosterWhiteboard/items/line-item/line-item';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {ImageItemSource} from '@Libraries/image-item.library';

export async function pasteCommonStyles(copiedProperties: CopyableItemStylesAndProperties, item: ItemType): Promise<void> {
  const stylesObj: DeepPartial<ItemObject> = {};

  if (!item.isSlideshowSlide()) {
    stylesObj.alpha = copiedProperties.opacity;
    stylesObj.flipX = copiedProperties.flipX;
    stylesObj.flipY = copiedProperties.flipY;

    if ('textStyles' in copiedProperties && ('textStyles' in item.toObject() || 'subtitleStyles' in item.toObject())) {
      stylesObj.scaleX = copiedProperties.scaleX;
      stylesObj.scaleY = copiedProperties.scaleY;
    }

    if ('subtitleStyles' in copiedProperties && ('subtitleStyles' in item.toObject() || 'textStyles' in item.toObject())) {
      stylesObj.scaleX = copiedProperties.scaleX;
      stylesObj.scaleY = copiedProperties.scaleY;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if (item.gitype !== ITEM_TYPE.QR_CODE && item.gitype !== ITEM_TYPE.TRANSCRIPT && item.gitype !== ITEM_TYPE.SLIDESHOW) {
      if ('subtitleStyles' in copiedProperties) {
        stylesObj.aura = copiedProperties.subtitleStyles.aura;
      }
    }
  }

  if (item.gitype !== ITEM_TYPE.QR_CODE && item.gitype !== ITEM_TYPE.TRANSCRIPT && item.gitype !== ITEM_TYPE.SLIDESHOW) {
    stylesObj.aura = copiedProperties.aura;
  }

  if (
    item.gitype === ITEM_TYPE.IMAGE ||
    item.gitype === ITEM_TYPE.RECTANGLE ||
    item.gitype === ITEM_TYPE.LINE ||
    item.gitype === ITEM_TYPE.VIDEO ||
    item.gitype === ITEM_TYPE.STICKER ||
    item.gitype === ITEM_TYPE.VECTOR ||
    item.gitype === ITEM_TYPE.IMAGESLIDE ||
    item.gitype === ITEM_TYPE.VIDEOSLIDE
  ) {
    if (copiedProperties.gitype === ITEM_TYPE.TEXT || copiedProperties.gitype === ITEM_TYPE.TEXTSLIDE) {
      if ('textStyles' in copiedProperties) {
        stylesObj.border = {
          solidBorderColor: copiedProperties.textStyles.strokeColor,
          solidBorderType: copiedProperties.textStyles.stroke ? BorderType.RECTANGLE_BORDER : BorderType.NONE,
          solidBorderThickness: copiedProperties.textStyles.strokeWidth * 100,
        };
      }
    } else if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
      if ('subtitleStyles' in copiedProperties) {
        stylesObj.border = {
          solidBorderColor: copiedProperties.subtitleStyles.sentenceTextStyles.strokeColor,
          solidBorderType: copiedProperties.subtitleStyles.sentenceTextStyles.stroke ? BorderType.RECTANGLE_BORDER : BorderType.NONE,
          solidBorderThickness: copiedProperties.subtitleStyles.sentenceTextStyles.strokeWidth * 100,
        };
      }
    } else if (copiedProperties.gitype === ITEM_TYPE.TAB || copiedProperties.gitype === ITEM_TYPE.TABLE || copiedProperties.gitype === ITEM_TYPE.MENU) {
      stylesObj.border = {
        solidBorderColor: copiedProperties.border.solidBorderColor,
        solidBorderType: copiedProperties.border.solidBorderType,
        solidBorderThickness: copiedProperties.border.solidBorderThickness * 10,
      };
    } else {
      stylesObj.border = copiedProperties.border;
    }
  }

  if (item.gitype === ITEM_TYPE.TAB || item.gitype === ITEM_TYPE.TABLE || item.gitype === ITEM_TYPE.MENU) {
    if (copiedProperties.gitype !== ITEM_TYPE.TEXT && copiedProperties.gitype !== ITEM_TYPE.TEXTSLIDE && copiedProperties.gitype !== ITEM_TYPE.TRANSCRIPT) {
      stylesObj.border = {
        solidBorderColor: copiedProperties.border.solidBorderColor,
        solidBorderType: copiedProperties.border.solidBorderType,
        solidBorderThickness: copiedProperties.border.solidBorderThickness / 10,
      };
    }
  }

  await item.updateFromObject(stylesObj);
}

export async function pasteStylesForStickerItem(copiedProperties: CopyableItemStylesAndProperties, stickerItem: StickerItem): Promise<void> {
  const stylesObj: DeepPartial<StickerItemObject> = {};
  if ('effects' in copiedProperties) {
    stylesObj.effects = copiedProperties.effects;
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.effects.tint = stickerItem.effects.tint;
        stylesObj.effects.tintColor = stickerItem.effects.tintColor;
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT) {
    if ('background' in copiedProperties) {
      stylesObj.effects = {
        edgeType: copiedProperties.background.fill.fillType === FillTypes.NONE || copiedProperties.background.rx === 0 ? EdgeType.NONE : EdgeType.CURVED,
        edgeThickness: (copiedProperties.background.rx / 100) * 28,
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.effects = {
        edgeType:
          copiedProperties.subtitleStyles.backgroundFill.fillType === FillTypes.NONE || copiedProperties.subtitleStyles.backgroundBorderRadius === 0
            ? EdgeType.NONE
            : EdgeType.CURVED,
        edgeThickness: (copiedProperties.subtitleStyles.backgroundBorderRadius / 100) * 28,
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE) {
    if ('rx' in copiedProperties && 'ry' in copiedProperties) {
      stylesObj.effects = {
        edgeType: copiedProperties.rx === 0 ? EdgeType.NONE : EdgeType.CURVED,
        edgeThickness: (copiedProperties.rx / 100) * 28,
      };
    }
  }

  await stickerItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, stickerItem);
}

export async function pasteStylesForImageItem(copiedProperties: CopyableItemStylesAndProperties, imageItem: ImageItem): Promise<void> {
  const stylesObj: DeepPartial<ImageItemObject> = {};
  if ('effects' in copiedProperties) {
    stylesObj.effects = copiedProperties.effects;
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.effects.tint = imageItem.effects.tint;
        stylesObj.effects.tintColor = imageItem.effects.tintColor;
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT) {
    if ('background' in copiedProperties) {
      stylesObj.effects = {
        edgeType: copiedProperties.background.fill.fillType === FillTypes.NONE || copiedProperties.background.rx === 0 ? EdgeType.NONE : EdgeType.CURVED,
        edgeThickness: (copiedProperties.background.rx / 100) * 28,
      };
    }
    if (imageItem.imageSource === ImageItemSource.DRAWING) {
      if ('textStyles' in copiedProperties) {
        stylesObj.effects = {
          tint: copiedProperties.textStyles.fill.fillType !== FillTypes.NONE,
          tintColor: copiedProperties.textStyles.fill.fillColor[0],
        };
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.effects = {
        edgeType:
          copiedProperties.subtitleStyles.backgroundFill.fillType === FillTypes.NONE || copiedProperties.subtitleStyles.backgroundBorderRadius === 0
            ? EdgeType.NONE
            : EdgeType.CURVED,
        edgeThickness: (copiedProperties.subtitleStyles.backgroundBorderRadius / 100) * 28,
      };

      if (imageItem.imageSource === ImageItemSource.DRAWING) {
        stylesObj.effects = {
          tint: copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillType !== FillTypes.NONE,
          tintColor: copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillColor[0],
        };
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE) {
    if ('rx' in copiedProperties && 'fill' in copiedProperties) {
      stylesObj.effects = {
        edgeType: copiedProperties.rx === 0 ? EdgeType.NONE : EdgeType.CURVED,
        edgeThickness: (copiedProperties.rx / 100) * 28,
        tint: copiedProperties.fill.fillType !== FillTypes.NONE,
        tintColor: copiedProperties.fill.fillColor[0],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if (imageItem.imageSource === ImageItemSource.DRAWING) {
      if ('qrForegroundColor' in copiedProperties)
        stylesObj.effects = {
          tint: true,
          tintColor: copiedProperties.qrForegroundColor,
        };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if (imageItem.imageSource === ImageItemSource.DRAWING) {
      if ('colors' in copiedProperties) {
        stylesObj.effects = {
          tint: true,
          tintColor: copiedProperties.colors[0],
        };
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.VECTOR || copiedProperties.gitype === ITEM_TYPE.LINE) {
    if (imageItem.imageSource === ImageItemSource.DRAWING) {
      if ('fill' in copiedProperties) {
        stylesObj.effects = {
          tint: copiedProperties.fill.fillType !== FillTypes.NONE,
          tintColor: copiedProperties.fill.fillColor[0],
        };
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TABLE || copiedProperties.gitype === ITEM_TYPE.MENU || copiedProperties.gitype === ITEM_TYPE.TAB) {
    if (imageItem.imageSource === ImageItemSource.DRAWING) {
      if ('textStyles' in copiedProperties) {
        stylesObj.effects = {
          tint: copiedProperties.textStyles.fill.fillType !== FillTypes.NONE,
          tintColor: copiedProperties.textStyles.fill.fillColor[0],
        };
      }
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      if (imageItem.imageSource === ImageItemSource.DRAWING) {
        if ('sentenceTextStyles' in copiedProperties) {
          stylesObj.effects = {
            tint: copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillType !== FillTypes.NONE,
            tintColor: copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillColor[0],
          };
        }
      }
    }
  }

  await imageItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, imageItem);
}

export async function pasteStylesForQrCodeItem(copiedProperties: CopyableItemStylesAndProperties, QRCodeItem: QRCodeItem): Promise<void> {
  const stylesObj: DeepPartial<QRCodeItemObject> = {};
  if ('qrForegroundColor' in copiedProperties) {
    stylesObj.qrForegroundColor = copiedProperties.qrForegroundColor;
  }
  if ('qrBackgroundColor' in copiedProperties) {
    stylesObj.qrBackgroundColor = copiedProperties.qrBackgroundColor;
  }
  if ('isBackgroundTransparent' in copiedProperties) {
    stylesObj.isBackgroundTransparent = copiedProperties.isBackgroundTransparent;
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT || copiedProperties.gitype === ITEM_TYPE.TEXTSLIDE) {
    if ('background' in copiedProperties) {
      stylesObj.isBackgroundTransparent = copiedProperties.background.fill.fillType === FillTypes.NONE;
      stylesObj.qrBackgroundColor = copiedProperties.background.fill.fillColor[0];
    }

    if ('textStyles' in copiedProperties) {
      stylesObj.qrForegroundColor = copiedProperties.textStyles.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.isBackgroundTransparent = copiedProperties.subtitleStyles.backgroundFill.fillType === FillTypes.NONE;
      stylesObj.qrBackgroundColor = copiedProperties.subtitleStyles.backgroundFill.fillColor[0];
      stylesObj.qrForegroundColor = copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TABLE || copiedProperties.gitype === ITEM_TYPE.MENU || copiedProperties.gitype === ITEM_TYPE.TAB) {
    if ('backgroundColor' in copiedProperties) {
      stylesObj.isBackgroundTransparent = (copiedProperties.backgroundType as LayoutBackgroundTypes) === LayoutBackgroundTypes.NONE;
      stylesObj.qrBackgroundColor = copiedProperties.backgroundColor;
    }

    if ('textStyles' in copiedProperties) {
      stylesObj.qrForegroundColor = copiedProperties.textStyles.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.qrForegroundColor = copiedProperties.colors[0];
      stylesObj.isBackgroundTransparent = true;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE || copiedProperties.gitype === ITEM_TYPE.LINE || copiedProperties.gitype === ITEM_TYPE.VECTOR) {
    if ('fill' in copiedProperties) {
      stylesObj.qrForegroundColor = copiedProperties.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.qrForegroundColor = copiedProperties.effects.tintColor;
      }
    }
  }

  await QRCodeItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, QRCodeItem);
}

export async function pasteStylesForVideoItem(copiedProperties: CopyableItemStylesAndProperties, videoItem: VideoItem): Promise<void> {
  const stylesObj: DeepPartial<VideoItemObject> = {};
  if ('effects' in copiedProperties) {
    stylesObj.effects = copiedProperties.effects;
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.effects.tint = videoItem.effects.tint;
        stylesObj.effects.tintColor = videoItem.effects.tintColor;
      }
    }
  }
  await videoItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, videoItem);
}

export async function pasteStylesForFancyTextItem(copiedProperties: CopyableItemStylesAndProperties, fancyTextItem: FancyTextItem): Promise<void> {
  const stylesObj: DeepPartial<FancyTextItemObject> = {};
  if ('fontFamily' in copiedProperties) {
    stylesObj.fontFamily = copiedProperties.fontFamily;
  }
  if ('colors' in copiedProperties) {
    stylesObj.colors = copiedProperties.colors;
  }
  if ('idFancyText' in copiedProperties) {
    stylesObj.idFancyText = copiedProperties.idFancyText;
  }
  if ('morphType' in copiedProperties) {
    stylesObj.morphType = copiedProperties.morphType;
  }
  if ('morphAmount' in copiedProperties) {
    stylesObj.morphAmount = copiedProperties.morphAmount;
  }
  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrForegroundColor' in copiedProperties) {
      const newColorsArray: RGBA[] = [];
      for (let i = 0; i < fancyTextItem.colors.length; i++) {
        newColorsArray[i] = copiedProperties.qrForegroundColor;
      }
      stylesObj.colors = newColorsArray;
    }
  }

  if ('textStyles' in copiedProperties) {
    const newColorsArray: RGBA[] = [];
    for (let i = 0; i < fancyTextItem.colors.length; i++) {
      newColorsArray[i] = copiedProperties.textStyles.fill.fillColor[0];
    }
    stylesObj.colors = newColorsArray;
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      const newColorsArray: RGBA[] = [];
      for (let i = 0; i < fancyTextItem.colors.length; i++) {
        newColorsArray[i] = copiedProperties.subtitleStyles.sentenceTextStyles.fill.fillColor[0];
      }
      stylesObj.colors = newColorsArray;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE || copiedProperties.gitype === ITEM_TYPE.LINE || copiedProperties.gitype === ITEM_TYPE.VECTOR) {
    if ('fill' in copiedProperties) {
      const newColorsArray: RGBA[] = [];
      for (let i = 0; i < fancyTextItem.colors.length; i++) {
        newColorsArray[i] = copiedProperties.fill.fillColor[0];
      }
      stylesObj.colors = newColorsArray;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        const newColorsArray: RGBA[] = [];
        for (let i = 0; i < fancyTextItem.colors.length; i++) {
          newColorsArray[i] = copiedProperties.effects.tintColor;
        }
        stylesObj.colors = newColorsArray;
      }
    }
  }

  await fancyTextItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, fancyTextItem);
}

export async function pasteStylesForTextItem(copiedProperties: CopyableItemStylesAndProperties, textItem: TextItem): Promise<void> {
  const stylesObj: DeepPartial<TextItemObject> = {};
  if ('textStyles' in copiedProperties) {
    const originalTextStyles = copiedProperties.textStyles;
    stylesObj.textStyles = {
      ...originalTextStyles,
      fontSize: copiedProperties.gitype === ITEM_TYPE.TAB ? textItem.textStyles.fontSize : copiedProperties.textStyles.fontSize,
    };
  }
  if ('backgroundType' in copiedProperties) {
    stylesObj.backgroundType = copiedProperties.backgroundType;
  }
  if ('backgroundColor' in copiedProperties) {
    stylesObj.backgroundColor = copiedProperties.backgroundColor;
  }
  if ('background' in copiedProperties) {
    stylesObj.background = copiedProperties.background;
  }
  if (
    'verticalAlign' in copiedProperties &&
    (copiedProperties.verticalAlign === TextVerticalAlignType.CENTER ||
      copiedProperties.verticalAlign === TextVerticalAlignType.TOP ||
      copiedProperties.verticalAlign === TextVerticalAlignType.BOTTOM)
  ) {
    stylesObj.verticalAlign = copiedProperties.verticalAlign;
  }

  if ('verticalPadding' in copiedProperties) {
    stylesObj.verticalPadding = copiedProperties.verticalPadding;
  }
  if ('list' in copiedProperties) {
    stylesObj.list = copiedProperties.list;
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE || copiedProperties.gitype === ITEM_TYPE.STICKER) {
    if ('effects' in copiedProperties) {
      stylesObj.background = {
        rx: copiedProperties.effects.edgeType === EdgeType.NONE ? 0 : (copiedProperties.effects.edgeThickness / 28) * 100,
        ry: copiedProperties.effects.edgeType === EdgeType.NONE ? 0 : (copiedProperties.effects.edgeThickness / 28) * 100,
      };
    }
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.textStyles = {
          fill: {
            fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
            fillColor: [copiedProperties.effects.tintColor],
          },
        };
      }
    }
  }
  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE) {
    if ('rx' in copiedProperties && 'ry' in copiedProperties) {
      stylesObj.background = {
        rx: copiedProperties.rx,
        ry: copiedProperties.ry,
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TABLE || copiedProperties.gitype === ITEM_TYPE.MENU || copiedProperties.gitype === ITEM_TYPE.TAB) {
    if ('backgroundColor' in copiedProperties) {
      stylesObj.background = {
        fill: {
          fillType: (copiedProperties.backgroundType as LayoutBackgroundTypes) === LayoutBackgroundTypes.NONE ? FillTypes.NONE : FillTypes.SOLID,
          fillColor: [copiedProperties.backgroundColor],
        },
      };
    }
  }

  if (!('textStyles' in copiedProperties)) {
    if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
      if ('subtitleStyles' in copiedProperties) {
        stylesObj.textStyles = copiedProperties.subtitleStyles.sentenceTextStyles;
        stylesObj.background = {
          fill: copiedProperties.subtitleStyles.backgroundFill,
          ry: copiedProperties.subtitleStyles.backgroundBorderRadius,
          rx: copiedProperties.subtitleStyles.backgroundBorderRadius,
        };
      }
    } else {
      stylesObj.textStyles = {
        ...stylesObj.textStyles,
        stroke: copiedProperties.border.solidBorderType !== BorderType.NONE,
        strokeWidth: copiedProperties.border.solidBorderThickness / 100,
        strokeColor: copiedProperties.border.solidBorderColor,
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrBackgroundColor' in copiedProperties) {
      stylesObj.background = {
        fill: {
          fillType: copiedProperties.isBackgroundTransparent ? FillTypes.NONE : FillTypes.SOLID,
          fillColor: [copiedProperties.qrBackgroundColor],
        },
      };
    }
    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.qrForegroundColor],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: copiedProperties.colors.length > 1 ? FillTypes.LINEAR_GRADIENT : FillTypes.SOLID,
          fillColor: [copiedProperties.colors[0], copiedProperties.colors[1]],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE || copiedProperties.gitype === ITEM_TYPE.LINE || copiedProperties.gitype === ITEM_TYPE.VECTOR) {
    if ('fill' in copiedProperties) {
      stylesObj.textStyles = {
        fill: copiedProperties.fill,
        stroke: copiedProperties.border.solidBorderType !== BorderType.NONE,
        strokeWidth: copiedProperties.border.solidBorderThickness / 100,
        strokeColor: copiedProperties.border.solidBorderColor,
      };
    }
  }

  await textItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, textItem);
}

export async function pasteStylesForVectorItem(copiedProperties: CopyableItemStylesAndProperties, vectorItem: VectorItem): Promise<void> {
  const stylesObj: DeepPartial<VectorItemObject> = {};
  if ('fill' in copiedProperties) {
    stylesObj.fill = copiedProperties.fill;
  }

  if ('textStyles' in copiedProperties) {
    stylesObj.fill = copiedProperties.textStyles.fill;
  }
  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.fill = copiedProperties.subtitleStyles.sentenceTextStyles.fill;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.fill = {
        fillType: FillTypes.SOLID,
        fillColor: [copiedProperties.qrForegroundColor],
      };
    }
  }
  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.fill = {
        fillType: copiedProperties.colors.length > 1 ? FillTypes.LINEAR_GRADIENT : FillTypes.SOLID,
        fillColor: [copiedProperties.colors[0], copiedProperties.colors[1]],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.fill = {
          fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
          fillColor: [copiedProperties.effects.tintColor],
        };
      }
    }
  }

  await vectorItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, vectorItem);
}

export async function pasteStylesForSlideShowItem(copiedProperties: CopyableItemStylesAndProperties, slideShowItem: SlideshowItem): Promise<void> {
  const stylesObj: DeepPartial<SlideshowItemObject> = {};
  const selectedSlide = slideShowItem.getSelectedSlide();
  if ('introAnimationPadding' in copiedProperties) {
    stylesObj.introAnimationPadding = copiedProperties.introAnimationPadding;
  }
  if ('introDelay' in copiedProperties) {
    stylesObj.introDelay = copiedProperties.introDelay;
  }
  if ('hasIntroOutroTransition' in copiedProperties) {
    stylesObj.hasIntroOutroTransition = copiedProperties.hasIntroOutroTransition;
  }
  if ('transition' in copiedProperties) {
    stylesObj.transition = copiedProperties.transition;
  }

  await selectedSlide.pasteStyles(copiedProperties);

  await slideShowItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, slideShowItem);
}

export async function pasteStylesForTextSlideItem(copiedProperties: CopyableItemStylesAndProperties, textSlideItem: TextSlideItem): Promise<void> {
  const stylesObj: DeepPartial<TextSlideItemObject> = {};
  if ('slideDuration' in copiedProperties) {
    stylesObj.slideDuration = copiedProperties.slideDuration;
  }

  await textSlideItem.updateFromObject(stylesObj, {undoable: false});
  await pasteStylesForTextItem(copiedProperties, textSlideItem);
}

export async function pasteStylesForImageSlideItem(copiedProperties: CopyableItemStylesAndProperties, imageSlideItem: ImageSlideItem): Promise<void> {
  const stylesObj: DeepPartial<ImageSlideItemObject> = {};
  if ('slideDuration' in copiedProperties) {
    stylesObj.slideDuration = copiedProperties.slideDuration;
  }
  if ('mediaSlide' in copiedProperties) {
    stylesObj.mediaSlide = copiedProperties.mediaSlide;
  }

  await imageSlideItem.updateFromObject(stylesObj, {undoable: false});
  await pasteStylesForImageItem(copiedProperties, imageSlideItem);
}

export async function pasteStylesForVideoSlideItem(copiedProperties: CopyableItemStylesAndProperties, videoSlideItem: VideoSlideItem): Promise<void> {
  const stylesObj: DeepPartial<VideoSlideItemObject> = {};
  if ('slideDuration' in copiedProperties) {
    stylesObj.slideDuration = copiedProperties.slideDuration;
  }
  if ('mediaSlide' in copiedProperties) {
    stylesObj.mediaSlide = copiedProperties.mediaSlide;
  }

  await videoSlideItem.updateFromObject(stylesObj, {undoable: false});
  await pasteStylesForVideoItem(copiedProperties, videoSlideItem);
}

export async function pasteStylesForTableItem(copiedProperties: CopyableItemStylesAndProperties, tableItem: TableItem): Promise<void> {
  const stylesObj: DeepPartial<TableItemObject> = {};
  if ('layoutStyle' in copiedProperties && copiedProperties.gitype === tableItem.gitype) {
    stylesObj.layoutStyle = copiedProperties.layoutStyle;
  }
  if ('alternateBackgroundColor1' in copiedProperties) {
    stylesObj.alternateBackgroundColor1 = copiedProperties.alternateBackgroundColor1;
  }
  if ('alternateBackgroundColor2' in copiedProperties) {
    stylesObj.alternateBackgroundColor2 = copiedProperties.alternateBackgroundColor2;
  }
  if ('highlightedBackgroundColor' in copiedProperties) {
    stylesObj.highlightedBackgroundColor = copiedProperties.highlightedBackgroundColor;
  }

  if ('highlightedTextColor' in copiedProperties) {
    stylesObj.highlightedTextColor = copiedProperties.highlightedTextColor;
  }
  if ('xSpacing' in copiedProperties) {
    stylesObj.xSpacing = copiedProperties.xSpacing;
  }
  if ('ySpacing' in copiedProperties) {
    stylesObj.ySpacing = copiedProperties.ySpacing;
  }
  if ('fontFamily2' in copiedProperties) {
    stylesObj.fontFamily2 = copiedProperties.fontFamily2;
  }
  if ('isBold2' in copiedProperties) {
    stylesObj.isBold2 = copiedProperties.isBold2;
  }
  if ('isItalic2' in copiedProperties) {
    stylesObj.isItalic2 = copiedProperties.isItalic2;
  }
  if ('underLine2' in copiedProperties) {
    stylesObj.underLine2 = copiedProperties.underLine2;
  }
  if ('lineThrough2' in copiedProperties) {
    stylesObj.lineThrough2 = copiedProperties.lineThrough2;
  }
  if ('backgroundType' in copiedProperties) {
    stylesObj.backgroundType = copiedProperties.backgroundType;
  }
  if ('backgroundColor' in copiedProperties) {
    stylesObj.backgroundColor = copiedProperties.backgroundColor;
  }
  if ('textStyles' in copiedProperties) {
    const originalTextStyles = copiedProperties.textStyles;
    stylesObj.textStyles = {
      ...originalTextStyles,
      fontSize: copiedProperties.gitype === ITEM_TYPE.TAB ? tableItem.textStyles.fontSize : copiedProperties.textStyles.fontSize,
    };
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.textStyles = copiedProperties.subtitleStyles.sentenceTextStyles;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT) {
    if ('background' in copiedProperties) {
      stylesObj.backgroundType = copiedProperties.background.fill.fillType === FillTypes.NONE ? LayoutBackgroundTypes.NONE : LayoutBackgroundTypes.COLOR;
      stylesObj.backgroundColor = copiedProperties.background.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.colors[0]],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrBackgroundColor' in copiedProperties) {
      stylesObj.backgroundType = copiedProperties.isBackgroundTransparent ? LayoutBackgroundTypes.NONE : LayoutBackgroundTypes.COLOR;
      stylesObj.backgroundColor = copiedProperties.qrBackgroundColor;
    }

    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.qrForegroundColor],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.textStyles = {
          fill: {
            fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
            fillColor: [copiedProperties.effects.tintColor],
          },
        };
      }
    }
  }

  await tableItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, tableItem);
}

export async function pasteStylesForMenuItem(copiedProperties: CopyableItemStylesAndProperties, menuItem: MenuItem): Promise<void> {
  const stylesObj: DeepPartial<MenuItemObject> = {};
  if ('iconsColor' in copiedProperties) {
    stylesObj.iconsColor = copiedProperties.iconsColor;
  }
  if ('iconsSize' in copiedProperties) {
    stylesObj.iconsSize = copiedProperties.iconsSize;
  }
  await menuItem.updateFromObject(stylesObj, {undoable: false});
  await pasteStylesForTableItem(copiedProperties, menuItem);
}

export async function pasteStylesForTabsItem(copiedProperties: CopyableItemStylesAndProperties, tabsItem: TabsItem): Promise<void> {
  const stylesObj: DeepPartial<TabsItemObject> = {};
  if ('separatorColor' in copiedProperties) {
    stylesObj.separatorColor = copiedProperties.separatorColor;
  }
  if ('separatorType' in copiedProperties) {
    stylesObj.separatorType = copiedProperties.separatorType;
  }
  if ('backgroundType' in copiedProperties) {
    stylesObj.backgroundType = copiedProperties.backgroundType;
  }
  if ('backgroundColor' in copiedProperties) {
    stylesObj.backgroundColor = copiedProperties.backgroundColor;
  }
  if ('textStyles' in copiedProperties) {
    stylesObj.textStyles = copiedProperties.textStyles;
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT) {
    if ('background' in copiedProperties) {
      stylesObj.backgroundType = copiedProperties.background.fill.fillType === FillTypes.NONE ? LayoutBackgroundTypes.NONE : LayoutBackgroundTypes.COLOR;
      stylesObj.backgroundColor = copiedProperties.background.fill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.backgroundType = copiedProperties.subtitleStyles.backgroundFill.fillType === FillTypes.NONE ? LayoutBackgroundTypes.NONE : LayoutBackgroundTypes.COLOR;
      stylesObj.backgroundColor = copiedProperties.subtitleStyles.backgroundFill.fillColor[0];
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrBackgroundColor' in copiedProperties) {
      stylesObj.backgroundType = copiedProperties.isBackgroundTransparent ? LayoutBackgroundTypes.NONE : LayoutBackgroundTypes.COLOR;
      stylesObj.backgroundColor = copiedProperties.qrBackgroundColor;
    }

    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.qrForegroundColor],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.textStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.colors[0]],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.textStyles = {
          fill: {
            fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
            fillColor: [copiedProperties.effects.tintColor],
          },
        };
      }
    }
  }

  await tabsItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, tabsItem);
}

export async function pasteStylesForImageBackgroundItem(copiedProperties: CopyableItemStylesAndProperties, imageBackgroundItem: ImageBackgroundItem): Promise<void> {
  await pasteStylesForImageItem(copiedProperties, imageBackgroundItem);
}

export async function pasteStylesForRectangleItem(copiedProperties: CopyableItemStylesAndProperties, rectangleItem: RectangleItem): Promise<void> {
  const stylesObj: DeepPartial<RectangleItemObject> = {};
  if ('fill' in copiedProperties) {
    stylesObj.fill = copiedProperties.fill;
  }
  if ('rx' in copiedProperties) {
    stylesObj.rx = copiedProperties.rx;
  }
  if ('ry' in copiedProperties) {
    stylesObj.ry = copiedProperties.ry;
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE || copiedProperties.gitype === ITEM_TYPE.STICKER || ITEM_TYPE.SLIDESHOW) {
    if ('effects' in copiedProperties) {
      stylesObj.rx = copiedProperties.effects.edgeType === EdgeType.NONE ? 0 : (copiedProperties.effects.edgeThickness / 28) * 100;
      stylesObj.ry = copiedProperties.effects.edgeType === EdgeType.NONE ? 0 : (copiedProperties.effects.edgeThickness / 28) * 100;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TEXT) {
    if ('background' in copiedProperties) {
      stylesObj.rx = copiedProperties.background.rx;
      stylesObj.ry = copiedProperties.background.ry;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.rx = copiedProperties.subtitleStyles.backgroundBorderRadius;
      stylesObj.ry = copiedProperties.subtitleStyles.backgroundBorderRadius;
      stylesObj.fill = copiedProperties.subtitleStyles.sentenceTextStyles.fill;
    }
  }

  if ('textStyles' in copiedProperties) {
    stylesObj.fill = copiedProperties.textStyles.fill;
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.fill = {
        fillType: FillTypes.SOLID,
        fillColor: [copiedProperties.qrForegroundColor],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.fill = {
        fillType: copiedProperties.colors.length > 1 ? FillTypes.LINEAR_GRADIENT : FillTypes.SOLID,
        fillColor: [copiedProperties.colors[0], copiedProperties.colors[1]],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.fill = {
          fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
          fillColor: [copiedProperties.effects.tintColor],
        };
      }
    }
  }

  await rectangleItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, rectangleItem);
}

export async function pasteStylesForLineItem(copiedProperties: CopyableItemStylesAndProperties, lineItem: LineItem): Promise<void> {
  const stylesObj: DeepPartial<LineItemObject> = {};
  if ('fill' in copiedProperties) {
    stylesObj.fill = copiedProperties.fill;
  }
  if ('lineDash' in copiedProperties) {
    stylesObj.lineDash = copiedProperties.lineDash;
  }
  if ('lineEnd' in copiedProperties) {
    stylesObj.lineEnd = copiedProperties.lineEnd;
  }
  if ('lineStart' in copiedProperties) {
    stylesObj.lineStart = copiedProperties.lineStart;
  }

  if ('textStyles' in copiedProperties) {
    stylesObj.fill = copiedProperties.textStyles.fill;
  }
  if (copiedProperties.gitype === ITEM_TYPE.TRANSCRIPT) {
    if ('subtitleStyles' in copiedProperties) {
      stylesObj.fill = copiedProperties.subtitleStyles.sentenceTextStyles.fill;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.fill = {
        fillType: FillTypes.SOLID,
        fillColor: [copiedProperties.qrForegroundColor],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.fill = {
        fillType: copiedProperties.colors.length > 1 ? FillTypes.LINEAR_GRADIENT : FillTypes.SOLID,
        fillColor: [copiedProperties.colors[0], copiedProperties.colors[1]],
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.fill = {
          fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
          fillColor: [copiedProperties.effects.tintColor],
        };
      }
    }
  }
  await lineItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, lineItem);
}

export async function pasteStylesForTranscriptItem(copiedProperties: CopyableItemStylesAndProperties, transcriptItem: TranscriptItem): Promise<void> {
  const stylesObj: DeepPartial<TranscriptItemObject> = {};
  if (
    'verticalAlign' in copiedProperties &&
    (copiedProperties.verticalAlign === TextVerticalAlignType.CENTER ||
      copiedProperties.verticalAlign === TextVerticalAlignType.TOP ||
      copiedProperties.verticalAlign === TextVerticalAlignType.BOTTOM)
  ) {
    stylesObj.verticalAlign = copiedProperties.verticalAlign;
  }

  await pasteStylesOnAllSubtitles(copiedProperties, transcriptItem);
  await transcriptItem.updateFromObject(stylesObj, {undoable: false});
  await pasteCommonStyles(copiedProperties, transcriptItem);
}

export async function pasteStylesOnAllSubtitles(copiedProperties: CopyableItemStylesAndProperties, transcriptItem: TranscriptItem): Promise<void> {
  let stylesObj: DeepPartial<SubtitleObject> = {};
  if ('subtitleStyles' in copiedProperties) {
    stylesObj = copiedProperties.subtitleStyles;
  }

  if ('textStyles' in copiedProperties) {
    stylesObj.sentenceTextStyles = stylesObj.sentenceTextStyles = {
      ...copiedProperties.textStyles,
      isBold: false,
      isItalic: false,
      underLine: false,
      lineThrough: false,
    };
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE) {
    if ('imageSource' in copiedProperties) {
      if (copiedProperties.imageSource === ImageItemSource.DRAWING) {
        stylesObj.sentenceTextStyles = {
          fill: {
            fillType: copiedProperties.effects.tint ? FillTypes.SOLID : FillTypes.NONE,
            fillColor: [copiedProperties.effects.tintColor],
          },
        };
      }
    }
  }

  if ('backgroundColor' in copiedProperties && 'backgroundColorAlpha' in copiedProperties) {
    if (copiedProperties.gitype === ITEM_TYPE.TEXT || copiedProperties.gitype === ITEM_TYPE.TEXTSLIDE) {
      if ('background' in copiedProperties) {
        stylesObj.backgroundFill = copiedProperties.background.fill;
        stylesObj.backgroundBorderRadius = copiedProperties.background.rx;
      }
    }
    if (copiedProperties.gitype === ITEM_TYPE.TABLE || copiedProperties.gitype === ITEM_TYPE.MENU || copiedProperties.gitype === ITEM_TYPE.TAB) {
      stylesObj.backgroundFill = {
        fillColor: [copiedProperties.backgroundColor],
        fillType: (copiedProperties.backgroundType as LayoutBackgroundTypes) === LayoutBackgroundTypes.NONE ? FillTypes.NONE : FillTypes.SOLID,
      };
    }
  }

  if (!('textStyles' in copiedProperties)) {
    stylesObj.sentenceTextStyles = {
      ...stylesObj.sentenceTextStyles,
      stroke: copiedProperties.border.solidBorderType !== BorderType.NONE,
      strokeWidth: copiedProperties.border.solidBorderThickness / 100,
      strokeColor: copiedProperties.border.solidBorderColor,
    };
  }

  if (copiedProperties.gitype === ITEM_TYPE.QR_CODE) {
    if ('qrBackgroundColor' in copiedProperties) {
      stylesObj.backgroundFill = {
        fillType: copiedProperties.isBackgroundTransparent ? FillTypes.NONE : FillTypes.SOLID,
        fillColor: [copiedProperties.qrBackgroundColor],
      };
    }
    if ('qrForegroundColor' in copiedProperties) {
      stylesObj.sentenceTextStyles = {
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [copiedProperties.qrForegroundColor],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.FANCY_TEXT) {
    if ('colors' in copiedProperties) {
      stylesObj.sentenceTextStyles = {
        fill: {
          fillType: copiedProperties.colors.length > 1 ? FillTypes.LINEAR_GRADIENT : FillTypes.SOLID,
          fillColor: [copiedProperties.colors[0], copiedProperties.colors[1]],
        },
      };
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE) {
    if ('rx' in copiedProperties) {
      stylesObj.backgroundBorderRadius = copiedProperties.rx;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.IMAGE || copiedProperties.gitype === ITEM_TYPE.STICKER) {
    if ('effects' in copiedProperties) {
      stylesObj.backgroundBorderRadius = copiedProperties.effects.edgeType === EdgeType.NONE ? 0 : (copiedProperties.effects.edgeThickness / 28) * 100;
    }
  }

  if (copiedProperties.gitype === ITEM_TYPE.RECTANGLE || copiedProperties.gitype === ITEM_TYPE.LINE || copiedProperties.gitype === ITEM_TYPE.VECTOR) {
    if ('fill' in copiedProperties) {
      stylesObj.sentenceTextStyles = {
        fill: copiedProperties.fill,
        stroke: copiedProperties.border.solidBorderType !== BorderType.NONE,
        strokeWidth: copiedProperties.border.solidBorderThickness / 100,
        strokeColor: copiedProperties.border.solidBorderColor,
      };
    }
  }

  if ('aura' in copiedProperties) {
    stylesObj.aura = copiedProperties.aura;
  }

  await transcriptItem.updateAllSubtitles(stylesObj, false);
}
