import type {ReactElement, ReactNode} from 'react';
import React from 'react';
import {Provider} from 'react-redux';
import {browserVersion, isSafari} from 'react-device-detect';
import ErrorBoundary from '../error-boundary';
import store from '../../store';
// import 'vite/modulepreload-polyfill';

window.PMW.mergePMW(window.PMW, {
  redux: {
    store,
  },
});

interface AppProps {
  children?: ReactNode;
  /**
   * Nothing should be passed in this on production. This is only meant for experimenting on dev
   */
  nonStrickModeChildren?: ReactNode;
  className?: string;
}

function App({...props}: AppProps): ReactElement {
  return (
    <div className={`${props.className ?? ''} ${isSafari && parseFloat(browserVersion) < 15 ? '-old-safari' : ''}`}>
      <Provider store={window.PMW.redux.store}>
        <ErrorBoundary>
          {props.nonStrickModeChildren}
          <React.StrictMode>{props.children}</React.StrictMode>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
