import type {MessageGrowlProps} from '@Components/message-growl';

export const PANEL_SMALL_BREAKING_POINT = 550;

export enum PANEL_MESSAGE_TYPE {
  NONE = 'NONE',
  WARNING = 'WARNING',
  INFO_PRIMARY = 'INFO_BLUE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO_NEUTRAL = 'INFO_NEUTRAL',
}

export interface PanelMessageProp {
  type: PANEL_MESSAGE_TYPE;
  message: string;
}

export interface BasePanel {
  panelId?: string;
  onBack?(artifact?: any): any;
  hideOnClose?: boolean;
  isOpenedInPopOver?: boolean;
}

export enum PanelDataKeys {
  GROWL = 'growlData',
}

enum PortedMessageGrowlProps {
  ICON = 'icon',
  TEXT = 'text',
}

export interface PanelGrowlData extends Pick<MessageGrowlProps, PortedMessageGrowlProps.ICON | PortedMessageGrowlProps.TEXT> {
  icon: string;
}
