import type {MouseEventHandler, Ref} from 'react';
import React, {useEffect} from 'react';
import './icon.scss';
import type {FlatIconType} from '@Components/icon-v2/icon.types';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '@Components/circular-progress-loader';
import type {ElementTestId} from '@Tests/tests.types';
import styles from './icon.module.scss';

/**
 * The new version of the icon.
 * Available on figma at https://www.figma.com/file/UXVmv6ZHvQVcRo9InK8aE7/Icon?node-id=0%3A1
 *
 */
export interface IconProps {
  id?: string;
  icon: string;
  className?: string;
  shape?: IconShape;
  type?: IconType;
  size?: IconSize;
  flatIconType?: FlatIconType;
  isSelected?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  'data-testid'?: ElementTestId;
  onClick?: MouseEventHandler; // | ((e: React.MouseEvent<HTMLElement>) => Promise<any>)
  isUsedAsButton?: boolean;
  ariaLabel?: string;
  isActive?: boolean;
}

export const Icon = React.forwardRef(
  (
    {
      id = '',
      className = '',
      shape = IconShape.NONE,
      type = IconType.NONE,
      size = IconSize.SIZE_ICON_24,
      isSelected = false,
      isDisabled = false,
      isLoading = false,
      onClick = (): void => {},
      flatIconType,
      ariaLabel,
      isUsedAsButton = false,
      isActive = false,
      ...props
    }: IconProps,
    ref: Ref<HTMLElement>
  ) => {
    const doesIconHaveShape = (): boolean => {
      return shape !== IconShape.NONE;
    };

    const isIconLargeOrExtraLarge = (): boolean => {
      return size === IconSize.SIZE_ICON_48 || size === IconSize.SIZE_ICON_64;
    };

    useEffect(() => {
      if (doesIconHaveShape() && isIconLargeOrExtraLarge()) {
        throw new Error('Icon shape circle or square cannot have size 48 or 64');
      }
    }, []);

    const getClassesForShape = (): string => {
      switch (shape) {
        case IconShape.NONE:
          return '-icon-only';
        case IconShape.SQUARE:
          return '-square';
        case IconShape.CIRCLE:
          return '-circle';
        default:
          return '';
      }
    };
    const getClassesForType = (): string => {
      switch (type) {
        case IconType.NONE:
          return '';
        case IconType.GHOST:
          return '-ghost';
        case IconType.GHOST_UNCOLORED:
          return '-ghost-uncolored';
        case IconType.TRANSPARENT:
          return '-transparent';
        case IconType.FLAT:
          return '-flat';
        case IconType.SECONDARY:
          return '-secondary';
        case IconType.SHADOW:
          return '-shadow';
        case IconType.PRIMARY:
          return '-primary';
        case IconType.SUCCESS:
          return '-success';
        case IconType.DANGER:
          return '-danger';
        case IconType.DARK:
          return '-dark';
        case IconType.DARK_SECONDARY:
          return '-dark-secondary';
        case IconType.PREMIUM:
          return '-premium';
        case IconType.PREMIUM_TWO:
          return '-premium-two';
        case IconType.PRIMARY_DARK:
          return '-primary-dark';
        case IconType.DARK_GHOST:
          return '-dark-ghost';
        default:
          return '';
      }
    };

    const getClassesForSize = (): string => {
      switch (size) {
        case IconSize.SIZE_ICON_12:
          return 'size-icon-12';
        case IconSize.SIZE_ICON_16:
          return 'size-icon-16';
        case IconSize.SIZE_ICON_20:
          return 'size-icon-20';
        case IconSize.SIZE_ICON_24:
          return 'size-icon-24';
        case IconSize.SIZE_ICON_32:
          return 'size-icon-32';
        case IconSize.SIZE_ICON_48:
          return 'size-icon-48';
        case IconSize.SIZE_ICON_64:
          return 'size-icon-64';
        default:
          return '';
      }
    };

    const isIconTypeFlat = (): boolean => {
      return type === IconType.FLAT;
    };

    const doesFlatIconTypeExists = (): boolean => {
      return !!flatIconType;
    };

    const getColorClassesForFlatIcon = (): string => {
      if (flatIconType?.color) {
        return `-${flatIconType.color.toLowerCase()}`;
      }
      return '';
    };

    const getTypeClassesForFlatIcon = (): string => {
      if (flatIconType?.type) {
        return `-${flatIconType.type.toLowerCase()}`;
      }
      return '';
    };

    const getIconClasses = (): string => {
      const classes = [
        className,
        props.icon,
        'pmw-icon',
        getClassesForShape(),
        getClassesForSize(),
        getClassesForType(),
        isDisabled ? '-disabled' : '',
        isSelected ? '-selected' : '',
        isUsedAsButton ? '-button' : '',
        isActive ? '-active' : '',
      ];

      if (isIconTypeFlat() || doesFlatIconTypeExists()) {
        classes.push(getColorClassesForFlatIcon() + getTypeClassesForFlatIcon());
      }

      return classes.join(' ');
    };

    const getLoaderSize = (): LOADER_SIZE => {
      switch (size) {
        case IconSize.SIZE_ICON_16:
          return LOADER_SIZE.XSMALL;
        case IconSize.SIZE_ICON_20:
          return LOADER_SIZE.SMALL;
        case IconSize.SIZE_ICON_24:
          return LOADER_SIZE.DEFAULT;
        case IconSize.SIZE_ICON_32:
          return LOADER_SIZE.MEDIUM;
        case IconSize.SIZE_ICON_48:
        case IconSize.SIZE_ICON_64:
          return LOADER_SIZE.LARGE;
        default:
          return LOADER_SIZE.DEFAULT;
      }
    };

    const getLoaderColor = (): LOADER_COLOR => {
      switch (type) {
        case IconType.NONE:
        case IconType.GHOST_UNCOLORED:
        case IconType.GHOST:
        case IconType.FLAT:
        case IconType.SECONDARY:
        case IconType.TRANSPARENT:
        case IconType.PREMIUM:
        case IconType.PREMIUM_TWO:
          return LOADER_COLOR.DARK;
        case IconType.PRIMARY:
          return LOADER_COLOR.PRIMARY;
        case IconType.DANGER:
          return LOADER_COLOR.ERROR;
        case IconType.PRIMARY_DARK:
        case IconType.DARK:
        case IconType.DARK_SECONDARY:
        case IconType.DARK_GHOST:
          return LOADER_COLOR.WHITE;
        default:
          return LOADER_COLOR.DARK;
      }
    };

    if (isLoading) {
      return <CircularProgressLoader color={getLoaderColor()} size={getLoaderSize()} classes={styles.loader} />;
    }

    return <i id={id} ref={ref} className={getIconClasses()} {...props} aria-label={ariaLabel} {...(!isDisabled && {onClick})} />;
  }
);
