import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {PanelMessageProp} from "@Components/panel/panel.types";

interface StateProps {
  isLoading: boolean
  panelMessage: PanelMessageProp | undefined;
}

const initialState: StateProps = {
  isLoading: false,
  panelMessage: undefined,
};

export const websiteBuilderSaveComponentTemplatePanel = createSlice({
  name: 'websiteBuilderSaveComponentTemplate',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setPanelMessage(state, action: PayloadAction<PanelMessageProp | undefined>) {
      state.panelMessage = action.payload;
    },
  },
});

export const {setIsLoading, setPanelMessage} = websiteBuilderSaveComponentTemplatePanel.actions;
export const websiteBuilderSaveComponentTemplatePanelReducer = websiteBuilderSaveComponentTemplatePanel.reducer;