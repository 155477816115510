import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export interface PanelStorage {
  startClosing: boolean;
  closed: boolean;
  data: Record<string, any>;
  isHidden?: boolean;
  hideOnClose?: boolean;
}

interface SizeData {
  width?: string;
  height?: string;
}

interface PanelProperties {
  hasSnapPoints?: boolean;
  snapPointsForBottomSheet?: number[];
  bottomSheetCloseThreshold?: number;
  margin?: number;
  stickToBottom?: boolean;
  noBorder?: boolean;
  noBorderRadius?: boolean;
}

interface UpdatePanelDataProps {
  panelId: string;
  data: Record<string, any>;
}

interface UpdatePanelPropertiesProps {
  panelId: string;
  hasSnapPoints?: boolean;
  snapPointsForBottomSheet?: number[];
  bottomSheetCloseThreshold?: number;
  margin?: number;
  stickToBottom?: boolean;
  noBorder?: boolean;
  noBorderRadius?: boolean;
}

interface UpdatePanelViewActionProps {
  sizeDataId: string;
  sizeData: SizeData;
}

interface StateProps {
  panelHashmap: Record<string, PanelStorage>;
  sizeDataHashmap: Record<string, SizeData>;
  panelPropertiesHashmap: Record<string, PanelProperties>;
}

const initialState: StateProps = {
  panelHashmap: {},
  sizeDataHashmap: {},
  panelPropertiesHashmap: {},
};

export interface InitPanelProps {
  id: string;
  hideOnClose?: boolean;
}

export const panelSlice = createSlice({
  name: 'Panel',
  initialState,
  reducers: {
    initPanel: (state, action: PayloadAction<InitPanelProps>) => {
      const {id, hideOnClose} = action.payload;
      if (state.panelHashmap[id]) {
        return;
      }
      state.panelHashmap[id] = {
        startClosing: false,
        data: {},
        closed: false,
      };

      state.panelHashmap[id].hideOnClose = hideOnClose;
      state.panelHashmap[id].isHidden = false;
      state.sizeDataHashmap[id] = {
        width: '',
        height: '',
      };
      state.panelPropertiesHashmap[id] = {
        hasSnapPoints: false,
        snapPointsForBottomSheet: undefined,
        bottomSheetCloseThreshold: undefined,
        stickToBottom: true,
        noBorderRadius: false,
        noBorder: false,
      };
    },
    updatePanelData: (state, action: PayloadAction<UpdatePanelDataProps>) => {
      if (state.panelHashmap[action.payload.panelId] === undefined) {
        return;
      }

      state.panelHashmap[action.payload.panelId].data = {...state.panelHashmap[action.payload.panelId].data, ...action.payload.data};
    },
    setPanelAsHiddenOnClose: (state, action: PayloadAction<string>) => {
      if (state.panelHashmap[action.payload] === undefined) {
        throw new Error(`Panel (${action.payload}) not initialized`);
      }

      state.panelHashmap[action.payload].hideOnClose = true;
    },
    updatePanelProperties: (state, action: PayloadAction<UpdatePanelPropertiesProps>) => {
      if (state.panelPropertiesHashmap[action.payload.panelId] === undefined) {
        throw new Error(`Panel (${action.payload.panelId}) not initialized`);
      }
      state.panelPropertiesHashmap[action.payload.panelId].hasSnapPoints = action.payload.hasSnapPoints;
      state.panelPropertiesHashmap[action.payload.panelId].snapPointsForBottomSheet = action.payload.snapPointsForBottomSheet;
      state.panelPropertiesHashmap[action.payload.panelId].bottomSheetCloseThreshold = action.payload.bottomSheetCloseThreshold;
      state.panelPropertiesHashmap[action.payload.panelId].margin = action.payload.margin;
      state.panelPropertiesHashmap[action.payload.panelId].stickToBottom = action.payload.stickToBottom;
      state.panelPropertiesHashmap[action.payload.panelId].noBorder = action.payload.noBorder;
      state.panelPropertiesHashmap[action.payload.panelId].noBorderRadius = action.payload.noBorderRadius;
    },
    updatePanelSizeData: (state, action: PayloadAction<UpdatePanelViewActionProps>) => {
      if (state.sizeDataHashmap[action.payload.sizeDataId]) {
        const panelSizeData = state.sizeDataHashmap[action.payload.sizeDataId];
        state.sizeDataHashmap[action.payload.sizeDataId] = {
          width: action.payload.sizeData.width ?? panelSizeData.width,
          height: action.payload.sizeData.height ?? panelSizeData.height,
        };
      } else {
        state.sizeDataHashmap[action.payload.sizeDataId] = {
          width: action.payload.sizeData.width ?? '',
          height: action.payload.sizeData.height ?? '',
        };
      }
    },
    removePanel: (state, action: PayloadAction<string>) => {
      delete state.panelHashmap[action.payload];
      delete state.sizeDataHashmap[action.payload];
    },
    hidePanel: (state, action: PayloadAction<string>): void => {
      if (state.panelHashmap[action.payload] === undefined) {
        throw new Error(`Panel (${action.payload}) not initialized`);
      }

      state.panelHashmap[action.payload].isHidden = true;
    },
    unhidePanel: (state, action: PayloadAction<string>): void => {
      if (state.panelHashmap[action.payload] === undefined) {
        throw new Error(`Panel (${action.payload}) not initialized`);
      }

      state.panelHashmap[action.payload].isHidden = false;
    },
    closePanel: (state, action: PayloadAction<string>) => {
      if (state.panelHashmap[action.payload] === undefined) {
        throw new Error(`Panel (${action.payload}) not initialized`);
      }

      if (!state.panelHashmap[action.payload].closed) {
        state.panelHashmap[action.payload].closed = true;
      }
    },
    triggerPanelClose: (state, action: PayloadAction<string>) => {
      if (state.panelHashmap[action.payload] === undefined) {
        throw new Error(`Panel (${action.payload}) not initialized`);
      }

      if (!state.panelHashmap[action.payload].startClosing) {
        state.panelHashmap[action.payload].startClosing = true;
      }
    },
  },
});

export const {initPanel, updatePanelData, updatePanelSizeData, updatePanelProperties, removePanel, closePanel, triggerPanelClose, hidePanel, unhidePanel, setPanelAsHiddenOnClose} =
  panelSlice.actions;
export const panelReducer = panelSlice.reducer;
