import type React from 'react';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {ElementDataType} from '@Libraries/add-media-library';
import type {GalleryPageURI} from '@Components/design-gallery/design-gallery.types';

export interface OnPosterCreatedCallbackParams {
  newlyCreatedPosterHashedId: string;
  posterCreatedFromMediaType: ElementDataType.IMAGE | ElementDataType.VIDEO;
  currentIndex?: number;
  totalPosterCreationsCount?: number;
}

export interface CreateDesignFromUploadOpts {
  maxSelectionNumber: number;
  onTriggered?: () => void;
  onPosterCreated: (params: OnPosterCreatedCallbackParams) => void;
  onAllPostersCreated?: (newlyCreatedPosterHashedIds: Array<string>, failedUploads: Array<File>) => void;
  onPosterCreationsStarted?: () => void;
  onErrorDuringUpload?: (file: File, currentUploadIndex?: number, totalItemsToUpload?: number) => void;
  idFolder?: string;
  footerPrimaryCtaText?: string;
  hideFooterSecondaryActions?: boolean;
}

export enum DesignSelectorTab {
  MY_DESIGNS = 'design-selector-my-designs',
  TEAM_SPACES = 'design-selector-team-spaces',
  GALLERY_TEMPLATES = 'design-selector-gallery-templates',
}

export interface DesignSelectorProps {
  onDesignListChange?: (designList: string[]) => void;
  selectedDesignIds?: string[];
  containerRef: React.RefObject<HTMLDivElement>;
  onDesignSelectionCallback?: (designId: string) => void;
  onTabSelected?: (newTabSelection: DesignSelectorTab) => void;
  designSelectorTab?: DesignSelectorTab;
  startDesignFromUploadBtnOpts?: CreateDesignFromUploadOpts;
  isLoading?: boolean;
  designGalleryStartingURI?: GalleryPageURI;
  hideTabContainer?: boolean;
  desktopClasses?: string;
  mobileClasses?: string;
  designGalleryHeight?: string;
  showSelectionCountForMobile?: boolean;
}

export const containerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: window.PMW.MOBILE_SCREEN_BREAKPOINT as number,
  },
  large: {
    minWidth: (window.PMW.MOBILE_SCREEN_BREAKPOINT as number) + 1,
  },
};

export const skeletonLoaderContainerQuery: ContainerQueryObject = {
  xxsmall: {
    minWidth: 0,
    maxWidth: 499,
  },
  xsmall: {
    minWidth: 500,
    maxWidth: 599,
  },
  small: {
    minWidth: 600,
    maxWidth: 768,
  },
  medium: {
    minWidth: 769,
    maxWidth: 819,
  },
  large: {
    minWidth: 820,
    maxWidth: 899,
  },
  xlarge: {
    minWidth: 900,
    maxWidth: 999,
  },
  xxlarge: {
    minWidth: 1000,
    maxWidth: 1099,
  },
  xxxlarge: {
    minWidth: 1100,
  },
};
