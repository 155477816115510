import type {VoidFunction} from '@Utils/general.util';
import type {TwoFactorVerificationUserActionType} from '@Panels/two-factor-authentication-verification-panel/two-factor-authentication-verification-panel.types';

type VerificationSuccessCallback = (userId?: number) => void;

export type TwoFactorVerificationCallback = VerificationSuccessCallback | VoidFunction;

export type TOTPVerificationResponse = {
  backupCodes: string;
};

export type TOTPVerificationFailResponse = {
  data: {
    reason: string;
  };
};

export type OnEnableTwoFactorAuthenticationCallback = (arg0?: TOTPVerificationResponse) => void;

export interface VerifyTOTPRequestParams {
  code: string;
  url: string;
}

export interface VerifyTOTPForRegistrationRequestParams {
  totp: string;
  logoutAllOtherDevices: boolean;
}

export interface VerifyTOTPForUserActionRequestParams extends VerifyTOTPRequestParams {
  type: string;
}

export enum LoginType {
  TOTP = 'TOTP',
  BACKUP_CODE = 'BACKUP_CODE',
}

export enum ManageTwoFactorAuthenticationPanelType {
  ENABLE_2FA = 'ENABLE_2FA',
  DISABLE_2FA = 'DISABLE_2FA',
  BACKUP_CODES = 'BACKUP_CODES',
}

export interface TwoFactorAuthenticationState {
  errorMessage: string;
  isLoading: boolean;
  loginType: LoginType;
  redirectUrl: string;
  isTOTPValidated: boolean;
  actionType?: TwoFactorVerificationUserActionType;
}
