import type {TwoFactorAuthenticationState} from '@Components/two-factor-authentication/two-factor-authentication.types';
import {LoginType} from '@Components/two-factor-authentication/two-factor-authentication.types';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {twoFactorAuthenticationExtraReducers} from '@Components/two-factor-authentication/two-factor-authentication-extra-reducers';
import {TwoFactorVerificationUserActionType} from '@Panels/two-factor-authentication-verification-panel/two-factor-authentication-verification-panel.types';

const initialState: TwoFactorAuthenticationState = {
  errorMessage: '',
  isLoading: false,
  loginType: LoginType.TOTP,
  redirectUrl: '',
  isTOTPValidated: false,
  actionType: undefined,
};

export const twoFactorAuthenticationSlice = createSlice({
  name: 'twoFactorAuthentication',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
    updateLoginType: (state, action: PayloadAction<LoginType>) => {
      state.loginType = action.payload;
    },
    clearError: (state) => {
      state.errorMessage = '';
    },
    setActionType: (state, action: PayloadAction<TwoFactorVerificationUserActionType | undefined>) => {
      state.actionType = action.payload;
    },
  },
  extraReducers: twoFactorAuthenticationExtraReducers,
});

export const {setError, setIsLoading, setRedirectUrl, updateLoginType, clearError, setActionType} = twoFactorAuthenticationSlice.actions;
export const twoFactorAuthenticationReducer = twoFactorAuthenticationSlice.reducer;
