import type {MouseEventHandler, ReactElement} from 'react';
import React, {useState, useRef} from 'react';
import {ClickableDiv} from '@Components/clickable-div';
import type {VideoOverlayPreviewProps} from '@Components/video-preview-new/components/video-overlay-preview/video-overlay-preview.types';
import styles from './video-overlay-preview.module.scss';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {ControlledSlider} from '@Components/controlled-slider';
import {Text, TextSize, TextType} from '@Components/text';
import {numberToMMSSFormat} from '@Utils/date.util';
import ReactPlayer from 'react-player';
import type {OnProgressProps} from 'react-player/types/base';
import {CircularProgressLoader} from '@Components/circular-progress-loader';

const VIDEO_WIDTH_BIAS = 32;

export function VideoOverlayPreview({...props}: VideoOverlayPreviewProps): ReactElement {
  const [seek, setSeek] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onSliderSeek = (val: number): void => {
    setSeek(() => {
      ref.current?.seekTo(val, 'seconds');
      return val;
    });
  };
  const onPlayPause = (): void => {
    setIsPlaying((prevState) => {
      return !prevState;
    });
  };
  const onReady = (): void => {
    setIsLoaded(true);
  };
  const onEnded = (): void => {
    setIsPlaying(false);
    setSeek(0);
  };
  const onProgress = ({playedSeconds}: OnProgressProps): void => {
    if (playedSeconds < props.duration) {
      setSeek(playedSeconds);
    }
  };
  const getVideoPlayer = (): ReactElement => {
    return (
      <>
        {!isLoaded ? <CircularProgressLoader classes={styles.videoLoader} /> : null}
        <ReactPlayer
          ref={ref}
          playsinline
          style={{display: isLoaded ? 'block' : 'none', margin: 'auto'}}
          onReady={onReady}
          playing={isPlaying}
          controls={false}
          volume={1}
          url={props.source}
          progressInterval={10}
          onProgress={onProgress}
          onEnded={onEnded}
          width={containerRef.current ? containerRef.current.offsetWidth - VIDEO_WIDTH_BIAS : '100%'}
        />
      </>
    );
  };

  return (
    <ClickableDiv ref={containerRef} onClick={onClick} className={`${styles.container} _full-width _full-height flex-v-row flex-content-between ${props.className ?? ''}`}>
      <Icon icon={'icon-close'} size={IconSize.SIZE_ICON_24} className={`content-body-white _cursor-pointer spacing-p-4 ${styles.closeIcon}`} onClick={props.onClose} />

      {getVideoPlayer()}

      <div className={`spacing-p-t-2 spacing-p-r-4 spacing-p-b-6 spacing-p-l-4 _full-width ${styles.footerActions}`}>
        <ControlledSlider min={0} max={props.duration} value={seek} onSlide={onSliderSeek} />
        <div className={'spacing-m-t-2 flex-h-row flex-content-between flex-align-items-center'}>
          <Icon icon={isPlaying ? 'icon-pause' : 'icon-play-video'} size={IconSize.SIZE_ICON_20} type={IconType.DARK} shape={IconShape.CIRCLE} onClick={onPlayPause} />
          <span>
            <Text val={numberToMMSSFormat(seek)} type={TextType.BODY} size={TextSize.XXSMALL} className={'content-body-white'} />
            <Text val={' / '} type={TextType.BODY} size={TextSize.XXSMALL} className={'content-body-white'} />
            <Text val={numberToMMSSFormat(props.duration)} type={TextType.BODY} size={TextSize.XXSMALL} className={'content-body-white'} />
          </span>
        </div>
      </div>
    </ClickableDiv>
  );
}
