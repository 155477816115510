import {useState, useEffect, useRef} from 'react';
import type {Timeout} from 'ahooks/es/useRequest/src/types';
import {noop} from '@Utils/general.util';

const DRAG_LONG_TAP_THRESHOLD = 10;

export default function useLongPress(callback = noop, incrementTimeout = 300, initialTimeout = 500) {
  const [startLongPress, setStartLongPress] = useState(false);
  const isFirstIteration = useRef(true);
  const isDragging = useRef(false);
  const startCoords = useRef({x: 0, y: 0});

  useEffect(() => {
    let timerId: Timeout | number = 0;

    if (startLongPress && !isDragging.current) {
      if (isFirstIteration.current) {
        timerId = setTimeout(callback, initialTimeout);
        isFirstIteration.current = false;
      } else {
        timerId = setTimeout(callback, incrementTimeout);
      }
    } else {
      clearTimeout(timerId);
    }

    return (): void => {
      clearTimeout(timerId);
    };
  }, [callback, incrementTimeout, startLongPress]);

  const handleStart = (event: any): void => {
    isFirstIteration.current = true;
    setStartLongPress(true);
    isDragging.current = false;

    if (event.touches) {
      startCoords.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    } else {
      startCoords.current = {
        x: event.clientX,
        y: event.clientY,
      };
    }
  };

  const handleMove = (event: any): void => {
    if (!startLongPress) return;

    const currentCoords = {
      x: event.touches ? event.touches[0].clientX : event.clientX,
      y: event.touches ? event.touches[0].clientY : event.clientY,
    };

    const dx = Math.abs(currentCoords.x - startCoords.current.x);
    const dy = Math.abs(currentCoords.y - startCoords.current.y);

    if (dx > DRAG_LONG_TAP_THRESHOLD || dy > DRAG_LONG_TAP_THRESHOLD) {
      isDragging.current = true;
      setStartLongPress(false);
    }
  };

  const handleEnd = (): void => {
    setStartLongPress(false);
    isDragging.current = false;
  };

  return {
    onMouseDown: handleStart,
    onMouseUp: handleEnd,
    onMouseLeave: handleEnd,
    onMouseMove: handleMove,
    onTouchStart: handleStart,
    onTouchEnd: handleEnd,
    onTouchMove: handleMove,
  };
}
