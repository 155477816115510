import {createSlice} from '@reduxjs/toolkit';
import type {EventPromotionReducerState} from '@Components/mystuff-event-promotion-page/mystuff-event-promotion-page.types';
import {getDummyEventItem} from '@Libraries/events.library';
import {LoadingStates} from '@Utils/loading.util';

const initialState: EventPromotionReducerState = {
  event: getDummyEventItem(),
  loadingState: LoadingStates.NOT_LOADED,
};
const eventPromotionPageSlice = createSlice({
    name: 'eventPromotionPageSlice',
    initialState,
    reducers: {},
    extraReducers: {}
});

export const eventPromotionPageReducer = eventPromotionPageSlice.reducer;