import {readCookie, setCookie} from '@Utils/cookie.util';
import {getUserPremiumLevel} from '@Libraries/user.library';

export enum USER_TYPES {
  DESIGNER = 'designer',
  TEACHER_DESIGNER = 'teacher-designer',
  TEACHER = 'teacher',
  STUDENT = 'student',
  RESELLER = 'reseller',
  NORMAL = 'normal',
}

export enum USER_PREMIUM_LEVELS {
  PAYG = 0,
  PREMIUM = 1,
  PREMIUM_PLUS = 2,
}

export const USER_SAFE_SEARCH_COOKIE = 'pmwssp';

export const isPAYGUser = (userPremiumLevel: USER_PREMIUM_LEVELS = getUserPremiumLevel()): boolean => {
  return userPremiumLevel === USER_PREMIUM_LEVELS.PAYG;
};

export const isPremiumUser = (userPremiumLevel: USER_PREMIUM_LEVELS = getUserPremiumLevel()): boolean => {
  return userPremiumLevel > USER_PREMIUM_LEVELS.PAYG;
};

export const isPremiumPlusUser = (userPremiumLevel: USER_PREMIUM_LEVELS = getUserPremiumLevel()): boolean => {
  return userPremiumLevel === USER_PREMIUM_LEVELS.PREMIUM_PLUS;
};

export const isUserStudent = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.STUDENT;
};

export const isUserDesigner = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.DESIGNER || userType === USER_TYPES.TEACHER_DESIGNER;
};

export const isUserTeacher = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.TEACHER || userType === USER_TYPES.TEACHER_DESIGNER;
};

export const isUserReseller = (userType: USER_TYPES): boolean => {
  return userType === USER_TYPES.RESELLER;
};

export const getUserSafeSearchPreferenceFromCookie = (): boolean => {
  const cookie = readCookie(USER_SAFE_SEARCH_COOKIE);
  if (!cookie) {
    return true;
  }

  return Number(cookie) === 1;
};

export const updateUserSafeSearchPreferenceCookie = (preference: number): void => {
  setCookie(USER_SAFE_SEARCH_COOKIE, String(preference), 180);
};

export const updateSafeSearchUserPreferenceInDB = async (preference: number): Promise<void> => {
  await window.PMW.writeLocal('user/updateUserSafeSearchPreference', {preference: preference});
};
